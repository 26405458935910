import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'

const RelevanceScore = ({ relevanceLevel }) => {
  switch (relevanceLevel) {
    case 'high':
      return <Icon className="relevance-score" name="star" color="green" size="small" />
    case 'normal':
      return <Icon className="relevance-score" name="star" color="yellow" size="small" />
    case 'low':
      return <Icon className="relevance-score" name="star outline" color="red" size="small" />
    default:
      return <Icon className="relevance-score" name="frown" color="grey" size="small" />
  }
}

RelevanceScore.propTypes = {
  relevanceLevel: PropTypes.oneOf(['high', 'normal', 'low']).isRequired,
}

export default RelevanceScore
