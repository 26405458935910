import React from 'react'
import { Grid, Menu } from 'semantic-ui-react'
import * as exclusionsFilters from '../../../constants/exclusions-filters'
import ExcludedTexts from './ExcludedTexts'
import ExclusionsFilterItem from './ExclusionsFilterItem'

const ExclusionsPage = () => (
  <div>
    <Grid>
      <Grid.Row centered>
        <Grid.Column width={2}>
          <Menu pointing secondary compact>
            <ExclusionsFilterItem filter={exclusionsFilters.TITLES}>Заголовки</ExclusionsFilterItem>
            <ExclusionsFilterItem filter={exclusionsFilters.TEXTS}>Тексты</ExclusionsFilterItem>
          </Menu>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column width={10}>
          <ExcludedTexts />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
)

export default ExclusionsPage
