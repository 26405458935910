import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { MenuItem } from 'semantic-ui-react'

const mapStateToProps = (state, { filter, match, history }) => {
  const { projectId, taskId } = match.params
  const path = ['', projectId, taskId, 'exclusions', filter]

  return {
    active: filter === match.params.filter,
    count: 0,
    onClick: () => {
      history.replace(path.join('/'))
    },
  }
}

const ExclusionsFilterItem = ({ active, onClick, children }) => (
  <MenuItem active={active} onClick={onClick}>
    {children}
  </MenuItem>
)

ExclusionsFilterItem.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default withRouter(connect(mapStateToProps)(ExclusionsFilterItem))
