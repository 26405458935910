import omit from 'lodash/omit'

export * from './actions'
export * from './selectors'

const PREFIX = '@requestErrors/'

export const RESET_STATE = `${PREFIX}RESET_STATE`

const requestErrors = (state = {}, action) => {
  const { type, error, payload } = action

  if (type === RESET_STATE) {
    return {
      ...omit(state, action.keys),
    }
  }

  if (error && payload) {
    return {
      ...state,
      [type]: {
        status: payload.status,
        rsaa: (action.meta && action.meta.rsaa) || (state[type] && state[type].rsaa),
      },
    }
  }

  return state
}

export default requestErrors
