import { combineReducers } from 'redux'
import { SELECT_AD_SUCCESS, DESELECT_AD_SUCCESS } from '../ads'

export * from './actions'
export * from './selectors'

const prefix = '@keywords/'

export const RESET = `${prefix}RESET`

export const FETCH_LIST_REQUEST = `${prefix}FETCH_LIST_REQUEST`
export const FETCH_LIST_SUCCESS = `${prefix}FETCH_LIST_SUCCESS`
export const FETCH_LIST_FAILURE = `${prefix}FETCH_LIST_FAILURE`

export const FETCH_GROUP_INFO_REQUEST = `${prefix}FETCH_GROUP_INFO_REQUEST`
export const FETCH_GROUP_INFO_SUCCESS = `${prefix}FETCH_GROUP_INFO_SUCCESS`
export const FETCH_GROUP_INFO_FAILURE = `${prefix}FETCH_GROUP_INFO_FAILURE`

export const UPDATE_GROUP_REQUEST = `${prefix}UPDATE_GROUP_REQUEST`
export const UPDATE_GROUP_SUCCESS = `${prefix}UPDATE_GROUP_SUCCESS`
export const UPDATE_GROUP_FAILURE = `${prefix}UPDATE_GROUP_FAILURE`

const increaseSelectedAds = (keyword, value) => ({
  ...keyword,
  ads: {
    ...keyword.ads,
    selected: keyword.ads.selected + value,
  },
})

const update = (state, action, changes) => {
  const { keywordId } = action.meta

  return {
    ...state,
    [keywordId]: {
      ...state[keywordId],
      ...changes,
    },
  }
}

const byId = (state = {}, action) => {
  switch (action.type) {
    case RESET:
      return {}
    case FETCH_LIST_SUCCESS:
      if (action.meta.lastTaskId !== action.meta.taskId) {
        return state
      }

      if (action.meta.currentPage === 1) {
        return action.payload.entities.keywords || {}
      }

      if (action.payload.entities.keywords) {
        return {
          ...state,
          ...action.payload.entities.keywords,
        }
      }

      return state
    case FETCH_LIST_FAILURE:
      return {}
    case FETCH_GROUP_INFO_REQUEST:
      return update(state, action, {
        isFetching: true,
        initialKeywords: [],
      })
    case FETCH_GROUP_INFO_SUCCESS:
      return update(state, action, {
        isFetching: false,
        ...action.payload.entities.keywords[action.meta.keywordId],
      })
    case FETCH_GROUP_INFO_FAILURE:
      return update(state, action, { isFetching: false })
    case UPDATE_GROUP_REQUEST:
      return update(state, action, { isUpdating: true })
    case UPDATE_GROUP_SUCCESS:
      return update(state, action, {
        ...action.payload.entities.keywords[action.meta.keywordId],
        isUpdating: false,
      })
    case UPDATE_GROUP_FAILURE:
      return update(state, action, { isUpdating: false })
    case SELECT_AD_SUCCESS:
      return {
        ...state,
        [action.meta.keywordId]: increaseSelectedAds(state[action.meta.keywordId], 1),
      }
    case DESELECT_AD_SUCCESS:
      return {
        ...state,
        [action.meta.keywordId]: increaseSelectedAds(state[action.meta.keywordId], -1),
      }
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_LIST_REQUEST:
      return true
    case FETCH_LIST_SUCCESS:
      return !!action.meta.nextPage
    case FETCH_LIST_FAILURE:
      return false
    default:
      return state
  }
}

export default combineReducers({
  byId,
  isFetching,
})
