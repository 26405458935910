import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Dimmer, Loader, Message, Table } from 'semantic-ui-react'
import { makeTrans } from 'pannier'
import formatDate from '../../../../../utils/formatDate'
import * as exclusionsFilters from '../../../../../constants/exclusions-filters'
import ExclusionsPagination from './ExclusionsPagination'

const ExcludedTextsWrap = styled.div`
  min-height: ${({ isFetching }) => (isFetching ? '150px' : 'unset')};
  max-height: calc(100vh - 300px);
  width: calc(100% + 10px);
  overflow-x: hidden;
  overflow-y: scroll;
`

const trans = makeTrans({
  total_plural_0: ({ count }) => `Всего ${count} штука`,
  total_plural_1: ({ count }) => `Всего ${count} штуки`,
  total_plural_2: ({ count }) => `Всего ${count} штук`,
})

const ExcludedTextsItem = ({ id, rejectedAt, text, isUpdating, onClick }) => (
  <Table.Row>
    <Table.Cell width={10}>{text}</Table.Cell>
    <Table.Cell width={4}>{formatDate(rejectedAt)}</Table.Cell>
    <Table.Cell width={2} textAlign="center">
      <Button onClick={() => onClick(id)} loading={isUpdating} circular icon="undo" color="olive" />
    </Table.Cell>
  </Table.Row>
)

const ExcludedTexts = ({
  onItemClick,
  filter,
  exclusions,
  isFetching,
  totalItems,
  sortColumn,
  sortDirection,
  sortExclusions,
}) => {
  const theader = (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell
          width={10}
          name="text"
          sorted={sortColumn === 'text' ? sortDirection : null}
          onClick={() => {
            sortExclusions(
              'text',
              sortColumn === 'text' && sortDirection === 'ascending' ? 'descending' : 'ascending',
            )
          }}
        >
          {filter === exclusionsFilters.TITLES ? 'Заголовок' : 'Текст'}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={4}
          name="rejectedAt"
          sorted={sortColumn === 'rejectedAt' ? sortDirection : null}
          onClick={() => {
            sortExclusions(
              'rejectedAt',
              sortColumn === 'rejectedAt' && sortDirection === 'descending'
                ? 'ascending'
                : 'descending',
            )
          }}
        >
          Дата исключения
        </Table.HeaderCell>
        <Table.HeaderCell disabled width={2} />
      </Table.Row>
    </Table.Header>
  )

  const tbody = (
    <Table.Body>
      {exclusions.map(({ id, rejectedAt, text, isUpdating }) => (
        <ExcludedTextsItem
          key={id}
          id={id}
          rejectedAt={rejectedAt}
          text={text}
          isUpdating={isUpdating}
          onClick={onItemClick}
        />
      ))}
    </Table.Body>
  )

  const tfooter = (
    <Table.Footer>
      <Table.Row>
        <Table.Cell width={12}>
          <ExclusionsPagination />
        </Table.Cell>
        <Table.Cell width={4} textAlign="right">
          {trans('total', { count: totalItems })}
        </Table.Cell>
      </Table.Row>
    </Table.Footer>
  )

  if (!isFetching && totalItems === 0) {
    return (
      <Message info>
        <Message.Header>Список пуст</Message.Header>
        <Message.Content>
          На данный момент нет исключённых текстов для текущей задачи.
        </Message.Content>
      </Message>
    )
  }

  return (
    <div>
      <Table sortable attached="top">
        {theader}
      </Table>
      <Dimmer.Dimmable as={ExcludedTextsWrap} isFetching={isFetching}>
        <Dimmer active={isFetching} inverted>
          <Loader size="large">Загрузка исключений...</Loader>
        </Dimmer>
        <Table attached celled striped>
          {tbody}
        </Table>
      </Dimmer.Dimmable>
      <Table attached="bottom">{tfooter}</Table>
    </div>
  )
}

const itemPropTypes = {
  id: PropTypes.string.isRequired,
  rejectedAt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isUpdating: PropTypes.bool.isRequired,
}

ExcludedTextsItem.propTypes = {
  ...itemPropTypes,
  onClick: PropTypes.func.isRequired,
}

ExcludedTexts.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func.isRequired,
  exclusions: PropTypes.arrayOf(PropTypes.shape(itemPropTypes)).isRequired,
  filter: PropTypes.string.isRequired,
  sortColumn: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
  sortExclusions: PropTypes.func.isRequired,
}

export default ExcludedTexts
