import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Icon } from 'semantic-ui-react'
import Tooltip from '../../../../components/Tooltip'
import { setShowTags, getShowTags } from '../../../../../stores/ads'

const NoTagsButtonWrap = styled.div`
  .btn-no-tags--active {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 16px;
      left: 10px;
      right: 10px;
      height: 5px;
      background: #e0e1e2;
      border-bottom: 2px solid #555;
      transform: rotate(30deg);
    }
  }

  .btn-no-tags--active:hover,
  .btn-no-tags--active:focus {
    &::after {
      background: #cacbcd;
    }
  }

  .btn-no-tags--active:active {
    &::after {
      background: #babbbc;
    }
  }

  #btn-no-tags-tooltip {
    width: 280px;
    text-align: center;
  }
`

const mapStateToProps = state => ({ showTags: getShowTags(state) })

class NoTagsButton extends Component {
  setShowTags = () => this.props.setShowTags(!this.props.showTags)

  render() {
    const { showTags } = this.props

    return (
      <NoTagsButtonWrap>
        <Button
          icon
          className={!showTags ? 'btn-no-tags--active' : undefined}
          onClick={this.setShowTags}
          data-for="btn-no-tags-tooltip"
          data-tip
        >
          <Icon fitted name="angle left" />
          tag
          <Icon fitted name="angle right" />
        </Button>
        <Tooltip
          id="btn-no-tags-tooltip"
          getContent={() => `${!showTags ? 'Не п' : 'П'}оказывать объявления с тегами`}
        />
      </NoTagsButtonWrap>
    )
  }
}

NoTagsButton.propTypes = {
  setShowTags: PropTypes.func.isRequired,
  showTags: PropTypes.bool.isRequired,
}

export default connect(
  mapStateToProps,
  { setShowTags },
)(NoTagsButton)
