import { camelizeKeys } from 'humps'
import { schema } from 'normalizr'

const normalizeKeys = entity => camelizeKeys(entity)

export const KEYWORD = new schema.Entity(
  'keywords',
  {},
  {
    processStrategy: normalizeKeys,
  },
)

export const KEYWORD_ARRAY = [KEYWORD]
