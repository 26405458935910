import { camelizeKeys } from 'humps'
import { schema } from 'normalizr'
import uuidv5 from 'uuid/v5'
import { adRating } from '../../utils/rating'
import prepareAdText from '../../utils/prepareAdText'

export const UUID_TITLE_NAMESPACE = '74bb11c5-8f31-418e-b5a8-f0da7a8c25aa'
export const UUID_TEXT_NAMESPACE = '0fcc0a81-4833-4d08-8b3b-9e271307072f'

const normalizeKeys = entity => camelizeKeys(entity)

const titleSchema = new schema.Entity(
  'titles',
  {},
  {
    processStrategy: normalizeKeys,
  },
)

const textSchema = new schema.Entity(
  'texts',
  {},
  {
    processStrategy: normalizeKeys,
  },
)

export const AD = new schema.Entity(
  'ads',
  {
    title: titleSchema,
    text: textSchema,
  },
  {
    processStrategy: entity => {
      const { title, text } = entity

      return {
        ...entity,
        title: {
          id: uuidv5(title.display_text, UUID_TITLE_NAMESPACE),
          ...title,
          renderText: prepareAdText(title.text, title.display_text),
        },
        text: {
          id: uuidv5(text.display_text, UUID_TEXT_NAMESPACE),
          ...text,
          renderText: prepareAdText(text.text, text.display_text),
        },
        rating: adRating(entity.quality, title.relevance_level, text.relevance_level),
      }
    },
  },
)

export const AD_ARRAY = [AD]
