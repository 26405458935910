import { RSAA, getJSON } from 'redux-api-middleware'
import { normalize } from 'normalizr'
import * as schemas from './schemas'
import { EXCLUSIONS_PAGE_SIZE } from '../../constants'
import * as types from './index'
import { getExclusion } from './selectors'
import * as exclusionsFilters from '../../constants/exclusions-filters'

// async actions

export const fetchTitleExclusions = (
  taskId,
  pageNumber = 1,
  sortColumn = 'rejectedAt',
  sortDirection = 'descending',
) => {
  const limit = EXCLUSIONS_PAGE_SIZE
  const offset = ((pageNumber || 1) - 1) * limit
  let sort = sortColumn === 'rejectedAt' ? 'rejected_at' : 'title'

  if (sortDirection === 'descending') {
    sort = `-${sort}`
  }

  return {
    [RSAA]: {
      endpoint: `/api/tasks/${taskId}/rejected-titles?limit=${limit}&offset=${offset}&sort=${sort}`,
      method: 'GET',
      credentials: 'same-origin',
      options: {
        authRequired: true,
      },
      bailout: state => state.titleExclusions.isFetching,
      types: [
        types.FETCH_TITLE_EXCLUSIONS_REQUEST,
        {
          type: types.FETCH_TITLE_EXCLUSIONS_SUCCESS,
          payload: (action, state, res) =>
            getJSON(res).then(({ data, meta }) => ({
              ...normalize(data, schemas.EXCLUSIONS_ARRAY),
              ...meta,
            })),
        },
        types.FETCH_TITLE_EXCLUSIONS_FAILURE,
      ],
    },
  }
}

export const fetchTextExclusions = (
  taskId,
  pageNumber = 1,
  sortColumn = 'rejectedAt',
  sortDirection = 'descending',
) => {
  const limit = EXCLUSIONS_PAGE_SIZE
  const offset = ((pageNumber || 1) - 1) * limit
  let sort = sortColumn === 'rejectedAt' ? 'rejected_at' : 'text'

  if (sortDirection === 'descending') {
    sort = `-${sort}`
  }

  return {
    [RSAA]: {
      endpoint: `/api/tasks/${taskId}/rejected-texts?limit=${limit}&offset=${offset}&sort=${sort}`,
      method: 'GET',
      credentials: 'same-origin',
      options: {
        authRequired: true,
      },
      bailout: state => state.textExclusions.isFetching,
      types: [
        types.FETCH_TEXT_EXCLUSIONS_REQUEST,
        {
          type: types.FETCH_TEXT_EXCLUSIONS_SUCCESS,
          payload: (action, state, res) =>
            getJSON(res).then(({ data, meta }) => ({
              ...normalize(data, schemas.EXCLUSIONS_ARRAY),
              ...meta,
            })),
        },
        types.FETCH_TEXT_EXCLUSIONS_FAILURE,
      ],
    },
  }
}

export const fetchExclusions = (filter, taskId, pageNumber, sortColumn, sortDirection) => {
  switch (filter) {
    case exclusionsFilters.TITLES:
      return fetchTitleExclusions(taskId, pageNumber, sortColumn, sortDirection)
    case exclusionsFilters.TEXTS:
      return fetchTextExclusions(taskId, pageNumber, sortColumn, sortDirection)

    // no default
  }

  throw new Error('Wrong filter was provided to fetchExclusions')
}

export const unrejectTitle = (taskId, titleId) => ({
  [RSAA]: {
    endpoint: `/api/tasks/${taskId}/rejected-titles/${titleId}`,
    method: 'DELETE',
    credentials: 'same-origin',
    options: {
      authRequired: true,
    },
    bailout: state => getExclusion(state, exclusionsFilters.TITLES, titleId).isUpdating,
    types: [
      {
        type: types.UNREJECT_TITLE_REQUEST,
        meta: {
          titleId,
        },
      },
      {
        type: types.UNREJECT_TITLE_SUCCESS,
        meta: {
          titleId,
        },
      },
      {
        type: types.UNREJECT_TITLE_FAILURE,
        meta: {
          titleId,
        },
      },
    ],
  },
})

export const unrejectText = (taskId, textId) => ({
  [RSAA]: {
    endpoint: `/api/tasks/${taskId}/rejected-texts/${textId}`,
    method: 'DELETE',
    credentials: 'same-origin',
    options: {
      authRequired: true,
    },
    bailout: state => getExclusion(state, exclusionsFilters.TEXTS, textId).isUpdating,
    types: [
      {
        type: types.UNREJECT_TEXT_REQUEST,
        meta: {
          textId,
        },
      },
      {
        type: types.UNREJECT_TEXT_SUCCESS,
        meta: {
          textId,
        },
      },
      {
        type: types.UNREJECT_TEXT_FAILURE,
        meta: {
          textId,
        },
      },
    ],
  },
})

export const unrejectItem = (filter, taskId, itemId) => {
  switch (filter) {
    case exclusionsFilters.TITLES:
      return unrejectTitle(taskId, itemId)
    case exclusionsFilters.TEXTS:
      return unrejectText(taskId, itemId)
    // no default
  }

  throw new Error('Wrong filter was provided to fetchExclusions')
}
