export * from './actions'

const prefix = '@modal/'

export const SHOW = `${prefix}SHOW`
export const HIDE = `${prefix}HIDE`

const initialState = {
  modalType: null,
  modalProps: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      }
    case HIDE:
      return initialState
    default:
      return state
  }
}
