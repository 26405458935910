export const getFilterRe = filter => {
  if (!/\s/.test(filter)) {
    return new RegExp(filter, 'i')
  }

  const preparedFilter = filter.split(' ').join('[^-\\s]*(\\s|-)[^-\\s]*')

  return new RegExp(`.*${preparedFilter}.*`, 'i')
}

export const loadAllFilters = () => JSON.parse(localStorage.getItem('adsFilter')) || {}

export const saveFilter = (id, filter = []) => {
  const oldValue = loadAllFilters()

  localStorage.setItem('adsFilter', JSON.stringify({ ...oldValue, [id]: filter }))
}

export const filterTexts = (filters, arr) =>
  arr.filter(item =>
    filters.every(filter => {
      const filterRe = getFilterRe(filter)

      return !filterRe.test(item.text)
    }),
  )

export const filterAds = (filters, ads) =>
  ads.filter(ad =>
    filters.every(filter => {
      const filterRe = getFilterRe(filter)

      return !filterRe.test(ad.title.text) && !filterRe.test(ad.text.text)
    }),
  )
