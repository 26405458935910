import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import 'nprogress/nprogress.css'
import { AuthRoute, withAuth } from 'auth-component'
import { NotificationEmitter } from 'pannier'
import ErrorHandler from './components/ErrorHandler'
import Header from './components/Header'
import IndexPage from './IndexPage'
import TasksListPage from './TasksListPage'
import TaskPage from './TaskPage'
import Page404 from './Page404'
import ModalManager from './ModalManager'
import AddKeywordsPage from './AddKeywordsPage'
import ExclusionsPage from './ExclusionsPage'
import * as routes from '../constants/routes'

const CenterWrap = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`

const DefaultLayout = ({ component: Component, ...props }) => (
  <Fragment>
    <Header {...props} />
    <ErrorHandler>
      <Component {...props} />
    </ErrorHandler>
    <ModalManager {...props} />
  </Fragment>
)

const RouteWithLayout = ({ layout: Layout, component, ...props }) => (
  <Route {...props} render={props => <Layout component={component} {...props} />} />
)

const IndexPageWithAuth = withAuth(IndexPage)
const TasksListPageWithAuth = withAuth(TasksListPage)
const AddKeywordsPageWithAuth = withAuth(AddKeywordsPage)
const ExclusionsPageWithAuth = withAuth(ExclusionsPage)
const TaskPageWithAuth = withAuth(TaskPage)

export default () => (
  <ErrorHandler>
    <CenterWrap>
      <Switch>
        <RouteWithLayout exact path="/" layout={DefaultLayout} component={IndexPageWithAuth} />
        <RouteWithLayout
          exact
          path={routes.TASKS_LIST_PAGE}
          layout={DefaultLayout}
          component={TasksListPageWithAuth}
        />
        <AuthRoute path={routes.AUTH_PAGE} />
        <RouteWithLayout
          exact
          path={routes.NEWKEYWORDS_PAGE}
          layout={DefaultLayout}
          component={AddKeywordsPageWithAuth}
        />
        <RouteWithLayout
          exact
          path={routes.EXCLUSIONS_PAGE}
          layout={DefaultLayout}
          component={ExclusionsPageWithAuth}
        />
        <RouteWithLayout
          exact
          path={routes.TASK_PAGE}
          layout={DefaultLayout}
          component={TaskPageWithAuth}
        />
        <Route component={Page404} />
      </Switch>
      <NotificationEmitter />
    </CenterWrap>
  </ErrorHandler>
)

DefaultLayout.propTypes = {
  component: PropTypes.func.isRequired,
}

RouteWithLayout.propTypes = {
  layout: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired,
}
