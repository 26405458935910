import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react'
import { isAuth } from 'auth-component'
import * as routes from '../../../../../../constants/routes'

const LoginButton = ({ isFetching, login, handleSignOut }) => {
  if (isAuth()) {
    return (
      <Button
        as={Link}
        to={routes.AUTH_PAGE}
        onClick={handleSignOut}
        size="tiny"
        loading={isFetching}
        icon
        labelPosition="right"
        className="btn-auth"
      >
        {login || 'Выйти'}
        <Icon name="sign out" />
      </Button>
    )
  }

  return (
    <Button
      as={Link}
      to={routes.AUTH_PAGE}
      size="tiny"
      icon
      labelPosition="right"
      className="btn-auth"
    >
      Войти
      <Icon name="sign in" />
    </Button>
  )
}

LoginButton.defaultProps = {
  login: null,
}

LoginButton.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  login: PropTypes.string,
  handleSignOut: PropTypes.func.isRequired,
}

export default LoginButton
