import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import qs from 'query-string'
import DebounceInput from 'react-debounce-input'
import { Input, Message } from 'semantic-ui-react'
import * as filters from '../../../../constants/keywords-filter'
import KeywordsList from './components/KeywordsList'
import { getVisibleKeywords } from '../../../../stores/keywords'

function mapStateToProps(
  state,
  {
    match: {
      params: { projectId, taskId, filter },
    },
    location,
    history,
  },
) {
  let keywords = getVisibleKeywords(state, filter || filters.DEFAULT_FILTER)

  if (!keywords.length && !filter) {
    keywords = getVisibleKeywords(state, filters.FILTER_ALL)
    history.replace(`/${projectId}/${taskId}/${filters.FILTER_ALL}${location.search}`)
  }

  return {
    selectedKeywordId: location.search && qs.parse(location.search).id,
    keywords,
    taskId,
  }
}

class VisisbleKeywordsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      textFilter: '',
    }
  }

  componentDidMount() {
    const { keywords, selectedKeywordId } = this.props
    if (keywords.length && !selectedKeywordId) {
      this.props.selectKeyword(keywords[0].id)
    }
  }

  componentDidUpdate(prevProps) {
    // Reset the search query, when passed a new list of keywords
    if (this.props.keywords !== prevProps.keywords) {
      this.resetTextFilter()
    }
  }

  resetTextFilter() {
    this.setState({ textFilter: '' })
  }

  render() {
    let { keywords } = this.props
    const { textFilter } = this.state

    if (textFilter && textFilter.length >= 2) {
      keywords = keywords.filter(x => x.keyword.indexOf(textFilter) >= 0)
    }

    return (
      <div>
        <DebounceInput
          element={Input}
          icon="search"
          placeholder="Поиск..."
          minLength={2}
          debounceTimeout={200}
          value={textFilter}
          onChange={e => this.setState({ textFilter: e.target.value })}
          style={{ width: '100%' }}
        />
        {keywords.length ? (
          <KeywordsList
            {...this.props}
            keywords={keywords}
            onItemClick={this.props.selectKeyword}
          />
        ) : (
          <Message info>
            <Message.Header>Ничего не найдено</Message.Header>
            <Message.Content>Попробуйте изменить условия поиска.</Message.Content>
          </Message>
        )}
      </div>
    )
  }
}

VisisbleKeywordsList.propTypes = {
  keywords: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      keyword: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectKeyword: PropTypes.func.isRequired,
  selectedKeywordId: PropTypes.string,
}

VisisbleKeywordsList.defaultProps = {
  selectedKeywordId: null,
}

// For test environment
export const Connected = connect(mapStateToProps)(VisisbleKeywordsList)

export default withRouter(Connected)
