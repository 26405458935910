import { createSelector } from 'reselect'
import omit from 'lodash/omit'
import * as exclusionsFilters from '../../constants/exclusions-filters'

const getTitleExclusionsById = (state, prefix) => state[`${prefix.slice(0, -1)}Exclusions`].byId
const getTextExclusionsById = (state, prefix) => state[`${prefix.slice(0, -1)}Exclusions`].byId

const makeSwitch = (filter, options) => {
  switch (filter) {
    case exclusionsFilters.TITLES:
      return options.titles
    case exclusionsFilters.TEXTS:
      return options.texts
    default:
      throw new Error(`Could not create selector. Unknown filter: ${filter}`)
  }
}

export const getExclusions = createSelector(
  getTitleExclusionsById,
  getTextExclusionsById,
  (state, filter) => filter,
  (titleExclusions, textExclusions, filter) =>
    makeSwitch(filter, {
      titles: Object.values(titleExclusions),
      texts: Object.values(textExclusions),
    }),
)

export const getExclusion = createSelector(
  getTitleExclusionsById,
  getTextExclusionsById,
  (state, filter, id) => ({ filter, id }),
  (titleExclusions, textExclusions, { filter, id }) =>
    makeSwitch(filter, {
      titles: titleExclusions[id],
      texts: textExclusions[id],
    }),
)

export const getExclusionsParams = createSelector(
  (state, filter) => ({ state, filter }),
  ({ state, filter }) =>
    makeSwitch(filter, {
      titles: omit(state.titleExclusions, 'byId'),
      texts: omit(state.textExclusions, 'byId'),
    }),
)
