import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { destroy as destroyForm, getFormValues } from 'redux-form'
import TagSettings from './components/TagSettings'
import { fetchTaskInfo, getTask } from '../../../../stores/tasks'
import { fetchGroupInfo, getKeyword } from '../../../../stores/keywords'
import { toEntity } from '../../../../utils/tagEditFormUtils'
import * as tagFormTypes from '../../../../constants/tag-form-types'

const taskFormValuesSelector = getFormValues('task')
const groupFormValuesSelector = getFormValues('group')

const mapStateToProps = (state, { taskId, keywordId }) => {
  const task = getTask(state, taskId) || { id: +taskId }
  const group = getKeyword(state, keywordId)
  const taskFormValues = taskFormValuesSelector(state)
  const groupFormValues = groupFormValuesSelector(state)
  const taskNewTags = toEntity(taskFormValues)
  const groupNewTags = toEntity(groupFormValues)

  return {
    task,
    group,
    taskHasChanges: !!taskNewTags && !isEqual(task && task.tags, taskNewTags),
    groupkHasChanges: !!groupNewTags && !isEqual(group && group.tags, groupNewTags),
  }
}

class TagSettingsContainer extends Component {
  componentDidMount() {
    const { task, group } = this.props

    if (group && !group.tags) {
      this.props.fetchGroupInfo(task.id, group.id)
    }

    if (task && !task.tags) {
      this.props.fetchTaskInfo(task.id)
    }
  }

  handleClose = (shouldCheckChanges = true) => {
    if (shouldCheckChanges && (this.props.taskHasChanges || this.props.groupkHasChanges)) {
      return
    }

    this.destroyForms()
    this.props.closeModal()
  }

  destroyForms = () => {
    this.props.destroyForm(tagFormTypes.TASK, tagFormTypes.GROUP)
  }

  render() {
    const { task, group, taskHasChanges, groupkHasChanges, availableTags } = this.props

    return (
      <TagSettings
        task={task}
        group={group}
        onModalClose={this.handleClose}
        taskHasChanges={taskHasChanges}
        groupkHasChanges={groupkHasChanges}
        availableTags={availableTags}
      />
    )
  }
}

TagSettingsContainer.defaultProps = {
  task: null,
  group: null,
  availableTags: null,
}

TagSettingsContainer.propTypes = {
  task: PropTypes.objectOf(PropTypes.any),
  group: PropTypes.objectOf(PropTypes.any),
  destroyForm: PropTypes.func.isRequired,
  fetchTaskInfo: PropTypes.func.isRequired,
  fetchGroupInfo: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  taskHasChanges: PropTypes.bool.isRequired,
  groupkHasChanges: PropTypes.bool.isRequired,
  availableTags: PropTypes.arrayOf(PropTypes.string),
}

export default connect(
  mapStateToProps,
  { fetchTaskInfo, fetchGroupInfo, destroyForm },
)(TagSettingsContainer)
