import React from 'react'
import PropTypes from 'prop-types'
import { TableRow, TableCell, Button, Icon } from 'semantic-ui-react'
import RelevanceScore from '../../RelevanceScore'
import AdText from '../../AdText'

const makeOnClick = (fn, id) => (fn ? () => fn(id) : null)

const RemovableText = ({ width, children, onClick }) => (
  <TableCell width={width} className="removable-text">
    {children}
    {onClick && (
      <Button className="icon remove-btn" circular compact onClick={onClick}>
        <Icon name="delete" />
      </Button>
    )}
  </TableCell>
)

const AdsListItem = ({ children, ad, active, rejectTitle, rejectText }) => (
  <TableRow positive={active}>
    <TableCell width={1}>
      <RelevanceScore relevanceLevel={ad.title.relevanceLevel} />
      <RelevanceScore relevanceLevel={ad.text.relevanceLevel} />
    </TableCell>
    <RemovableText onClick={makeOnClick(rejectTitle, ad.title.id)} width={4}>
      <AdText chunks={ad.title.renderText} />
    </RemovableText>
    <RemovableText onClick={makeOnClick(rejectText, ad.text.id)} width={7}>
      <AdText chunks={ad.text.renderText} />
    </RemovableText>
    <TableCell width={2} textAlign="center">
      {ad.rating}
    </TableCell>
    <TableCell width={2} textAlign="center">
      {children}
    </TableCell>
  </TableRow>
)

RemovableText.defaultProps = {
  onClick: null,
}

RemovableText.propTypes = {
  width: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onClick: PropTypes.func,
}

AdsListItem.defaultProps = {
  active: false,
  rejectTitle: null,
  rejectText: null,
}

AdsListItem.propTypes = {
  ad: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.shape({
      renderText: PropTypes.any.isRequired,
      relevanceLevel: PropTypes.string.isRequired,
    }).isRequired,
    text: PropTypes.shape({
      renderText: PropTypes.any.isRequired,
      relevanceLevel: PropTypes.string.isRequired,
    }).isRequired,
    rating: PropTypes.number.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  active: PropTypes.bool,
  rejectTitle: PropTypes.func,
  rejectText: PropTypes.func,
}

export default AdsListItem
