import * as types from './index'

export const showModal = (modalType, modalProps = {}) => ({
  type: types.SHOW,
  modalType,
  modalProps,
})

export const hideModal = modalType => ({
  type: types.HIDE,
  modalType,
})
