import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Button, Table } from 'semantic-ui-react'
import { isFetching } from '../../../../../../../stores/keywords'
import AdsListItem from './AdsListItem'

const SelectedAdsWrap = styled.div`
  height: calc(30vh - 100px);
  width: calc(100% + 10px);
  overflow-x: hidden;
  overflow-y: scroll;

  & .relevance-score {
    display: block;
    padding-left: 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const mapStateToProps = state => ({ isKeywordsFetching: isFetching(state) })

const SelectedAds = ({ ads, deselectAd, isKeywordsFetching }) => (
  <div>
    <SelectedAdsWrap>
      <Table attached="top" celled striped>
        <Table.Body>
          {ads.map(item => (
            <AdsListItem key={item.id} ad={item} active>
              <Button
                circular
                icon="undo"
                loading={item.isChanging}
                onClick={() => deselectAd(item)}
                disabled={isKeywordsFetching}
              />
            </AdsListItem>
          ))}
        </Table.Body>
      </Table>
    </SelectedAdsWrap>
    <Table attached="bottom">
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="5" textAlign="right">
            Отобрано: {ads.length} шт.
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  </div>
)

SelectedAds.propTypes = {
  ads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isChanging: PropTypes.bool,
    }),
  ).isRequired,
  deselectAd: PropTypes.func.isRequired,
  isKeywordsFetching: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps)(SelectedAds)
