import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import qs from 'query-string'
import {
  deselectAd,
  rejectText,
  rejectTitle,
  selectAd,
  hideAd,
  getAvailableAds,
  getIntersectionsAds,
  getSelectedAds,
} from '../../../../../../stores/ads'
import { getNextKeyword } from '../../../../../../stores/keywords'
import AdsPanel from './components/AdsPanel'
import { FILTER_ACCESSIBLE } from '../../../../../../constants/keywords-filter'

const filterAds = (availableAds, maxAds, minRating) => {
  if (!maxAds || !minRating) {
    return availableAds
  }

  return availableAds.filter(ad => minRating <= ad.rating).slice(0, maxAds)
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { taskId, filter },
    },
    location,
    intersections,
  },
) => {
  const keywordId = location.search && qs.parse(location.search).id

  return {
    availableAds: intersections
      ? getIntersectionsAds(state, taskId)
      : getAvailableAds(state, taskId),
    selectedAds: getSelectedAds(state),
    keywordId,
    taskId,
    nextKeyword: getNextKeyword(state, filter || FILTER_ACCESSIBLE, keywordId),
  }
}

class AdsPanelContainer extends Component {
  handleSelectAd = ad => {
    const { taskId, keywordId, selectAd } = this.props
    selectAd(taskId, keywordId, ad.id)
  }

  handleDeselectAd = ad => {
    const { taskId, keywordId, deselectAd } = this.props
    deselectAd(taskId, keywordId, ad.id)
  }

  handleRejectTitle = textId => this.props.rejectTitle(this.props.taskId, textId)

  handleRejectText = textId => this.props.rejectText(this.props.taskId, textId)

  render() {
    const { availableAds, selectedAds, maxAds, minRating, nextKeyword, hideAd } = this.props

    return (
      <AdsPanel
        availableAds={filterAds(availableAds, maxAds, minRating)}
        selectedAds={selectedAds}
        maxAds={maxAds}
        minRating={minRating}
        nextKeyword={nextKeyword}
        hideAd={maxAds && hideAd}
        rejectTitle={this.handleRejectTitle}
        rejectText={this.handleRejectText}
        selectAd={this.handleSelectAd}
        deselectAd={this.handleDeselectAd}
      />
    )
  }
}

AdsPanelContainer.defaultProps = {
  maxAds: null,
  minRating: null,
  nextKeyword: null,
}

AdsPanelContainer.propTypes = {
  taskId: PropTypes.string.isRequired,
  keywordId: PropTypes.string.isRequired,
  selectAd: PropTypes.func.isRequired,
  deselectAd: PropTypes.func.isRequired,
  hideAd: PropTypes.func.isRequired,
  rejectTitle: PropTypes.func.isRequired,
  rejectText: PropTypes.func.isRequired,
  availableAds: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedAds: PropTypes.arrayOf(PropTypes.object).isRequired,
  maxAds: PropTypes.number,
  minRating: PropTypes.number,
  nextKeyword: PropTypes.shape({
    id: PropTypes.string.isRequired,
    keyword: PropTypes.string.isRequired,
  }),
}

export const ConnectedAdsPanelContainer = connect(
  mapStateToProps,
  {
    selectAd,
    deselectAd,
    hideAd,
    rejectTitle,
    rejectText,
  },
)(AdsPanelContainer)

export default withRouter(ConnectedAdsPanelContainer)
