import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Pagination } from 'pannier'
import { fetchExclusions, getExclusionsParams } from '../../../../../stores/exclusions'

const mapStateToProps = (
  state,
  {
    match: {
      params: { filter },
    },
  },
) => {
  const { sortColumn, sortDirection, currentPage, totalPages } = getExclusionsParams(state, filter)

  return {
    sortColumn,
    sortDirection,
    currentPage,
    totalPages,
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    sortColumn,
    sortDirection,
    match: {
      params: { taskId, filter },
    },
  },
) => ({
  setPage: pageNumber =>
    dispatch(fetchExclusions(filter, taskId, pageNumber, sortColumn, sortDirection)),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Pagination),
)
