import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Header, Message } from 'semantic-ui-react'

const RequestErrorMessageWrap = styled.div`
  padding-top: 30vh;
  margin: 0 auto;
  width: 30%;
`

const RequestErrorMessage = ({ message }) => (
  <RequestErrorMessageWrap>
    <Message error>
      <Header textAlign="center">{message}</Header>
    </Message>
  </RequestErrorMessageWrap>
)

RequestErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
}

export default RequestErrorMessage
