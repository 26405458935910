import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Input } from 'semantic-ui-react'

const IncrementalInputWrap = styled.div`
  display: flex;
  align-items: baseline;
  margin-right: 20px;

  .ui.button {
    padding-bottom: 9px !important;
  }

  .num-input > input {
    height: 36px;
    width: 36px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-right: 1px !important;
    text-align: center !important;
    border-radius: 0 !important;
  }
`

class IncrementalInput extends Component {
  getValidValue = val => {
    const { min, max } = this.props

    return Math.min(Math.max(val, min), max)
  }

  setValue = val => {
    const validValue = this.getValidValue(val)

    if (validValue !== this.props.value) {
      this.props.setValue(validValue)
    }
  }

  handleChange = e => /^\d+$/.test(e.target.value) && this.setValue(parseInt(e.target.value, 10))

  render() {
    const { value, min, max } = this.props

    return (
      <IncrementalInputWrap>
        <Button
          icon="minus"
          attached="left"
          disabled={value === min}
          onClick={() => this.setValue(value - 1)}
        />
        <Input className="num-input" value={value} onChange={this.handleChange} />
        <Button
          icon="add"
          attached="right"
          disabled={value === max}
          onClick={() => this.setValue(value + 1)}
        />
      </IncrementalInputWrap>
    )
  }
}

IncrementalInput.defaultProps = {
  min: 0,
  max: Infinity,
}

IncrementalInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setValue: PropTypes.func.isRequired,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default IncrementalInput
