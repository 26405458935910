import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Button, List, Loader, Message } from 'semantic-ui-react'
import TaskControls from './TaskControls'

const TasksListWrap = styled.div`
  margin-bottom: 30px;
`

const TasksList = ({ projectId, tasks, isFetching, canLoadMore, onLoadMoreClick }) => {
  const isEmpty = tasks.length === 0

  if (isEmpty && isFetching) {
    return (
      <Loader
        active
        inline="centered"
        content="Пожалуйста, подождите. Идёт загрузка списка задач..."
      />
    )
  }

  if (isEmpty && !canLoadMore) {
    return <Message info content="Не найдено ни одной задачи." />
  }

  return (
    <TasksListWrap>
      <List divided relaxed className="task-list">
        {tasks.map(task => (
          <List.Item key={task.id}>
            <List.Content floated="right">
              <TaskControls projectId={projectId} taskId={task.id} />
            </List.Content>
            <List.Content>
              <List.Header as={Link} to={`${projectId}/${task.id}`}>
                {task.name}
              </List.Header>
              <List.Description>
                <strong>{task.subject}</strong> {task.description}
              </List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
      {canLoadMore && (
        <div style={{ textAlign: 'center' }}>
          <Button disabled={isFetching} onClick={onLoadMoreClick} content="Загрузить ещё" />
        </div>
      )}
    </TasksListWrap>
  )
}

TasksList.propTypes = {
  projectId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  canLoadMore: PropTypes.bool.isRequired,
  onLoadMoreClick: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      subject: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ).isRequired,
}

TasksList.defaultProps = {
  isFetching: false,
}

export default TasksList
