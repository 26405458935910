import * as tagNames from '../constants/tag-names'

const getTagName = tag => {
  const strippedTag = tag.slice(1, -1)

  switch (strippedTag) {
    case tagNames.PRICE:
      return tagNames.PRICE
    case tagNames.SHOP:
      return tagNames.SHOP
    case tagNames.DIS:
      return tagNames.DIS
    case tagNames.DISCOUNT:
      return tagNames.DISCOUNT
    // no default
  }

  if (
    ['city_nom', 'city_acc', 'city_loc', 'city_dat', 'city_gen', 'city_ins'].includes(strippedTag)
  ) {
    return tagNames.CITY
  }

  return null
}

export default getTagName
