import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { showModal } from '../../stores/modal'

class WithOpenModal extends Component {
  openModal = (modalType, modalProps) => this.props.showModal(modalType, modalProps)

  render() {
    return this.props.children(this.openModal)
  }
}

WithOpenModal.propTypes = {
  children: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
}

export default connect(
  null,
  { showModal },
)(WithOpenModal)
