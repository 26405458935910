import { createSelector } from 'reselect'
import * as selectedStatuses from '../../constants/selected-statuses'
import { filterTexts, filterAds } from '../../utils/adsFilter'

const getAdsById = state => state.ads.adsById
const getTitlesById = state => state.ads.titlesById
const getTextsById = state => state.ads.textsById
const getHiddenAdsIds = state => state.ads.hiddenAds
const getFilters = state => state.ads.filters

export const getShowTags = state => state.ads.showTags

export const getFilter = createSelector(
  getFilters,
  (state, taskId) => taskId,
  (filters, id) => filters[id] || [],
)

const ratingComparator = (l, r) => {
  if (l.rating === r.rating) {
    return 0
  }

  return l.rating > r.rating ? -1 : 1
}

export const getAd = createSelector(
  getAdsById,
  (state, id) => id,
  (ads, id) => ads[id],
)

export const getTitle = createSelector(
  getTitlesById,
  (state, id) => id,
  (titles, id) => titles[id],
)

export const getText = createSelector(
  getTextsById,
  (state, id) => id,
  (texts, id) => texts[id],
)

const noTagsRe = /<(city_(nom|acc|loc|dat|get|ins)|price|shop|metro|dis(count)?|size[^>]*)>/

export const getAds = createSelector(
  getAdsById,
  getTitlesById,
  getTextsById,
  getShowTags,
  (ads, titles, texts, showTags) => {
    const assembledAds = Object.values(ads)
      .map(ad => ({
        ...ad,
        title: titles[ad.title],
        text: texts[ad.text],
      }))
      .filter(ad => ad.title && ad.text) // to prevent selection of ads with recently excluded title/text

    if (showTags) {
      return assembledAds
    }

    return assembledAds.filter(
      ({ title, text }) => !noTagsRe.test(title.displayText + text.displayText),
    )
  },
)

export const getAvailableAds = createSelector(
  getAds,
  getFilter,
  getHiddenAdsIds,
  (ads, filters, hiddenAds) =>
    filterAds(filters, ads.filter(x => !x.selected && !hiddenAds[x.id]).sort(ratingComparator)),
)

export const getSelectedAds = createSelector(
  getAds,
  ads => ads.filter(x => x.selected).sort(ratingComparator),
)

const applyNoTagsFilter = (texts, showTags) =>
  showTags ? texts : texts.filter(text => !noTagsRe.test(text.displayText))

export const getTitles = createSelector(
  getTitlesById,
  getShowTags,
  (titles, showTags) => applyNoTagsFilter(Object.values(titles), showTags),
)

export const getAvailableTitles = createSelector(
  getTitles,
  getFilter,
  (titles, filters) =>
    filterTexts(
      filters,
      titles.filter(title => !title.status || title.status === selectedStatuses.UNSELECTED),
    ),
)

export const getSelectedTitles = createSelector(
  getTitles,
  titles => titles.filter(title => title.status && title.status !== selectedStatuses.UNSELECTED),
)

export const getTexts = createSelector(
  getTextsById,
  getShowTags,
  (texts, showTags) => applyNoTagsFilter(Object.values(texts), showTags),
)

export const getAvailableTexts = createSelector(
  getTexts,
  getFilter,
  (texts, filters) =>
    filterTexts(
      filters,
      texts.filter(text => !text.status || text.status === selectedStatuses.UNSELECTED),
    ),
)

export const getSelectedTexts = createSelector(
  getTexts,
  texts => texts.filter(text => text.status && text.status !== selectedStatuses.UNSELECTED),
)

export const getIntersectionsAds = createSelector(
  getAvailableAds,
  ads =>
    ads.filter(
      ad =>
        ad.title.status &&
        ad.title.status !== selectedStatuses.UNSELECTED &&
        ad.text.status &&
        ad.text.status !== selectedStatuses.UNSELECTED,
    ),
)
