import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import qs from 'query-string'
import styled from 'styled-components'
import { Loader, Message } from 'semantic-ui-react'
import { getMaxAds, getMinRating } from '../../../../stores/tasks'
import { fetchAds } from '../../../../stores/ads'
import { fetchGroupInfo, getKeyword } from '../../../../stores/keywords'
import * as menuItems from '../../../../constants/ads-menu'
import AdsHeader from './components/AdsHeader'
import AdsMenu from './components/AdsMenu'
import AdsPanel from './components/AdsPanel'
import TitlesPanel from './components/TitlesPanel'
import TextsPanel from './components/TextsPanel'
import AdsViewSwitch from './components/AdsViewSwitch'
import NoTagsButton from './components/NoTagsButton'
import AdsListControls from './components/AdsListControls'
import * as adsViews from '../../../../constants/ads-views'

const AdsContainerWrap = styled.div`
  .ads-heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .ui.header {
      margin-bottom: 0;
    }
  }

  .ads-container {
    position: relative;
  }

  .ads-controls {
    display: flex;
    position: absolute;
    top: 6px;
    right: 0;
  }
`

function mapStateToProps(
  state,
  {
    match: {
      params: { taskId },
    },
    location,
  },
) {
  const keywordId = location.search && qs.parse(location.search).id
  const keyword = keywordId ? getKeyword(state, keywordId) : null

  return {
    showPanel: state.ads.activeMenuItem,
    isFetching: state.ads.isFetching,
    keywordId,
    keyword,
    taskId,
    maxAds: getMaxAds(state, taskId),
    minRating: getMinRating(state, taskId),
  }
}

class AdsContainer extends Component {
  state = { view: adsViews.SIMPLE }

  componentDidMount() {
    if (this.props.keywordId) {
      this.props.fetchAds(this.props.taskId, this.props.keywordId)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.keywordId &&
      (this.props.taskId !== prevProps.taskId || this.props.keywordId !== prevProps.keywordId)
    ) {
      this.props.fetchAds(this.props.taskId, this.props.keywordId)
    }
  }

  setView = view => this.setState({ view })

  renderPanel() {
    if (!this.props.keywordId) {
      return <Message info content="Выберите ключевую фразу." />
    }

    if (this.state.view === adsViews.SIMPLE) {
      return <AdsPanel maxAds={this.props.maxAds} minRating={this.props.minRating} />
    }

    switch (this.props.showPanel) {
      case menuItems.ADS:
        return <AdsPanel />
      case menuItems.TITLES:
        return <TitlesPanel />
      case menuItems.TEXTS:
        return <TextsPanel />
      case menuItems.INTERSECTIONS:
        return <AdsPanel intersections />
      default:
        return null
    }
  }

  render() {
    const { view } = this.state
    const { taskId, keywordId, isFetching, keyword } = this.props

    return (
      <AdsContainerWrap>
        {keywordId && <AdsHeader taskId={taskId} keywordId={keywordId} keyword={keyword} />}
        <div className="ads-container">
          <div className="ads-controls">
            <NoTagsButton />
            <AdsViewSwitch setView={this.setView} view={view} />
          </div>
          {view === adsViews.SIMPLE ? <AdsListControls taskId={taskId} /> : <AdsMenu />}
          {isFetching ? (
            <Loader
              active
              inline="centered"
              size="large"
              style={{ marginTop: '20vh' }}
              content="Загрузка объявлений..."
            />
          ) : (
            this.renderPanel()
          )}
        </div>
      </AdsContainerWrap>
    )
  }
}

AdsContainer.defaultProps = {
  keywordId: null,
  keyword: null,
  maxAds: null,
  minRating: null,
}

AdsContainer.propTypes = {
  taskId: PropTypes.string.isRequired,
  keywordId: PropTypes.string,
  keyword: PropTypes.shape({
    initialKeywords: PropTypes.arrayOf(PropTypes.string),
    initialKeywordsCount: PropTypes.number,
  }),
  showPanel: PropTypes.oneOf([
    menuItems.ADS,
    menuItems.TITLES,
    menuItems.TEXTS,
    menuItems.INTERSECTIONS,
  ]).isRequired,
  isFetching: PropTypes.bool.isRequired,
  fetchAds: PropTypes.func.isRequired,
  maxAds: PropTypes.number,
  minRating: PropTypes.number,
}

// For test environment
export const Connected = connect(
  mapStateToProps,
  {
    fetchAds,
    fetchGroupInfo,
  },
)(AdsContainer)

export default withRouter(Connected)
