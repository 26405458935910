import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Modal } from 'semantic-ui-react'
import { reduxForm, Field, submit } from 'redux-form'
import { DropdownReduxForm, InputReduxForm, TextAreaReduxForm } from 'pannier'
import * as taskSubjects from '../../../../../constants/task-subjects'
import * as validators from '../../../../../utils/validators'

const subjectOptions = [
  { key: 1, text: 'Продажа матрасов', value: taskSubjects.MATTRESS },
  { key: 2, text: 'Продажа светильников', value: taskSubjects.LIGHT },
  { key: 3, text: 'Услуги медицинские', value: taskSubjects.MEDIC },
]

const subjectRequired = validators.makeRequired('Пожалуйста, выберите тематику.')
const nameRequired = validators.makeRequired('Пожалуйста, укажите название задачи.')
const nameMinLength = validators.makeMinLength(
  'Название должно состоять из 3-х или более символов.',
  3,
)

const TaskFormModal = ({ handleSubmit, isUpdating, closeModal, dispatch }) => (
  <Modal size="tiny" open closeOnEscape={false} closeOnDocumentClick={false}>
    <Modal.Header>Новая задача</Modal.Header>
    <Modal.Content>
      <Form onSubmit={handleSubmit}>
        <Field
          name="subject"
          component={DropdownReduxForm}
          validate={subjectRequired}
          options={subjectOptions}
          label="Тематика"
          placeholder="Выберите тематику"
          required
        />
        <Field
          name="name"
          component={InputReduxForm}
          validate={[nameRequired, nameMinLength]}
          label="Название"
          placeholder="Название задачи..."
          required
        />
        <Field name="description" component={TextAreaReduxForm} label="Описание" />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button
        primary
        type="submit"
        loading={isUpdating}
        content="Сохранить"
        onClick={() => dispatch(submit('newTask'))}
      />
      <Button disabled={isUpdating} onClick={closeModal} content="Отмена" />
    </Modal.Actions>
  </Modal>
)

TaskFormModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'newTask',
})(TaskFormModal)
