import { camelizeKeys } from 'humps'
import { schema } from 'normalizr'

const normalizeKeys = entity => camelizeKeys(entity)

export const TASK = new schema.Entity(
  'tasks',
  {},
  {
    processStrategy: entity => {
      const maxAds = JSON.parse(localStorage.getItem('maxAds')) || {}
      const minRating = JSON.parse(localStorage.getItem('minRating')) || {}

      return normalizeKeys({
        ...entity,
        maxAds: maxAds[entity.id] || 5,
        minRating: minRating[entity.id] || 5,
      })
    },
  },
)

export const TASK_ARRAY = [TASK]
