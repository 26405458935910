import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchProjectDetails, getProject } from 'projects-embed'
import Breadcrumbs from './components/Breadcrumbs'
import { fetchTaskInfo, getTask } from '../../../../../stores/tasks'
import createPageTitle from '../../../../../utils/createPageTitle'

const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId, taskId },
    },
  },
) => {
  const task = getTask(state, taskId)
  const project = getProject(state, projectId)
  return {
    projectName: project && project.name,
    taskName: task && task.name,
  }
}

class BreadcrumbsContainer extends Component {
  componentDidMount() {
    const { projectId, taskId } = this.props.match.params
    if (!this.props.projectName && projectId) {
      this.props.fetchProjectDetails(projectId)
    }
    if (!this.props.taskName && taskId) {
      this.props.fetchTaskInfo(taskId)
    }
  }

  componentDidUpdate() {
    this.setPageTitle()
  }

  setPageTitle() {
    const { match, projectName, taskName } = this.props
    document.title = createPageTitle(match.path, { projectName, taskName })
  }

  render() {
    const { match, projectName, taskName } = this.props

    return <Breadcrumbs match={match} projectName={projectName} taskName={taskName} />
  }
}

BreadcrumbsContainer.defaultProps = {
  projectName: null,
  taskName: null,
}

BreadcrumbsContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    params: PropTypes.shape({
      projectId: PropTypes.string,
      taskId: PropTypes.string,
    }),
  }).isRequired,
  projectName: PropTypes.string,
  taskName: PropTypes.string,
  fetchProjectDetails: PropTypes.func.isRequired,
  fetchTaskInfo: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  { fetchProjectDetails, fetchTaskInfo },
)(BreadcrumbsContainer)
