import { combineReducers } from 'redux'
import * as types from './constants'

export * from './actions'
export * from './selectors'
export * from './constants'

const login = (state = null, action) => {
  switch (action.type) {
    case types.FETCH_PROFILE_SUCCESS:
      return action.payload.email
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_PROFILE_REQUEST:
      return true
    case types.FETCH_PROFILE_SUCCESS:
    case types.FETCH_PROFILE_FAILURE:
      return false
    default:
      return state
  }
}

export default combineReducers({
  login,
  isFetching,
})
