import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import styled from 'styled-components'
import { Input, Button, Icon } from 'semantic-ui-react'

const ShopFieldArrayWrap = styled.div`
  .item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .ui.input {
      margin-right: 5px;
    }

    .remove.icon {
      margin-right: 10px;
    }
  }
`

class ShopFieldArray extends Component {
  componentDidMount() {
    if (!this.props.fields.length) {
      this.props.fields.push()
    }
  }

  renderAddButton = () => {
    const { fields } = this.props

    return <Button basic positive icon="add" onClick={() => fields.length < 3 && fields.push()} />
  }

  render() {
    const { fields } = this.props

    return (
      <ShopFieldArrayWrap>
        {fields.map((name, idx) => (
          <div key={name} className="item">
            <Field name={name} component={Input} placeholder="Введите название..." />
            <Icon link name="remove" size="large" color="red" onClick={() => fields.remove(idx)} />
            {idx === fields.length - 1 && this.renderAddButton()}
          </div>
        ))}
        {!fields.length && this.renderAddButton()}
      </ShopFieldArrayWrap>
    )
  }
}

ShopFieldArray.propTypes = {
  fields: PropTypes.shape({
    length: PropTypes.number.isRequired,
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
}

export default ShopFieldArray
