import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Popup, Button, Dropdown } from 'semantic-ui-react'
import { CloseCross } from 'pannier'
import MinusFilterForm from './components/MinusFilterForm'
import { setFilter, getFilter } from '../../../../../../../../stores/ads'

const popupStyles = { minWidth: '330px' }

const toValue = filter => filter.join('\n')
const toFilter = value =>
  [
    ...new Set(
      value.split('\n').map(val =>
        val
          .toLowerCase()
          .replace(/[^\s\-a-zа-я0-9]/g, '')
          .replace(/-/g, ' ')
          .replace(/\s{2,}/g, ' ')
          .trim(),
      ),
    ),
  ].filter(i => i)

const mapStateToProps = (state, { taskId }) => ({
  filter: getFilter(state, taskId),
})

class MinusFilter extends Component {
  state = {
    isPopupOpen: false,
    value: toValue(this.props.filter),
  }

  onChange = (e, { value }) => this.setState({ value })

  onSubmit = () => {
    const filter = toFilter(this.state.value)

    this.props.setFilter(this.props.taskId, filter)
    this.setState({ value: toValue(filter), isPopupOpen: false })
  }

  openPopup = () => this.setState({ isPopupOpen: true })

  closePopup = () => this.setState({ isPopupOpen: false })

  render() {
    const { value, isPopupOpen } = this.state
    const triggerProps = { content: 'Минус-слова' }

    return (
      <Popup
        trigger={
          this.props.isMenuItem ? <Dropdown.Item {...triggerProps} /> : <Button {...triggerProps} />
        }
        content={
          <Fragment>
            <MinusFilterForm onSubmit={this.onSubmit} onChange={this.onChange} value={value} />
            <CloseCross onClose={this.closePopup} />
          </Fragment>
        }
        position="bottom left"
        open={isPopupOpen}
        on="click"
        onOpen={this.openPopup}
        onClose={this.closePopup}
        style={popupStyles}
      />
    )
  }
}

MinusFilter.defaultProps = {
  isMenuItem: false,
}

MinusFilter.propTypes = {
  taskId: PropTypes.string.isRequired,
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFilter: PropTypes.func.isRequired,
  isMenuItem: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  { setFilter },
)(MinusFilter)
