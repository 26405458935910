import { combineReducers } from 'redux'
import union from 'lodash/union'
import omit from 'lodash/omit'
import isPlainObject from 'lodash/isPlainObject'

export * from './actions'
export * from './selectors'

const prefix = '@tasks/'

export const FETCH_LIST_REQUEST = `${prefix}FETCH_LIST_REQUEST`
export const FETCH_LIST_SUCCESS = `${prefix}FETCH_LIST_SUCCESS`
export const FETCH_LIST_FAILURE = `${prefix}FETCH_LIST_FAILURE`

export const FETCH_INFO_REQUEST = `${prefix}FETCH_INFO_REQUEST`
export const FETCH_INFO_SUCCESS = `${prefix}FETCH_INFO_SUCCESS`
export const FETCH_INFO_FAILURE = `${prefix}FETCH_INFO_FAILURE`

export const CREATE_REQUEST = `${prefix}CREATE_REQUEST`
export const CREATE_SUCCESS = `${prefix}CREATE_SUCCESS`
export const CREATE_FAILURE = `${prefix}CREATE_FAILURE`

export const UPDATE_REQUEST = `${prefix}UPDATE_REQUEST`
export const UPDATE_SUCCESS = `${prefix}UPDATE_SUCCESS`
export const UPDATE_FAILURE = `${prefix}UPDATE_FAILURE`

export const DELETE_REQUEST = `${prefix}DELETE_REQUEST`
export const DELETE_SUCCESS = `${prefix}DELETE_SUCCESS`
export const DELETE_FAILURE = `${prefix}DELETE_FAILURE`

export const FETCH_REPORT_REQUEST = `${prefix}FETCH_REPORT_REQUEST`
export const FETCH_REPORT_SUCCESS = `${prefix}FETCH_REPORT_SUCCESS`
export const FETCH_REPORT_FAILURE = `${prefix}FETCH_REPORT_FAILURE`

export const SET_MAX_ADS = `${prefix}SET_MAX_ADS`
export const SET_MIN_RATING = `${prefix}SET_MIN_RATING`

const update = (state, action, changes) => {
  const { taskId } = action.meta

  return {
    ...state,
    [taskId]: {
      ...state[taskId],
      ...changes,
    },
  }
}

const writeTaskProp = propName => (id, val) => {
  let obj = JSON.parse(localStorage.getItem(propName)) || {}

  if (!isPlainObject(obj)) {
    obj = {}
  }

  obj[id] = val
  localStorage.setItem(propName, JSON.stringify(obj))
}

const writeMaxAds = writeTaskProp('maxAds')
const writeMinRating = writeTaskProp('minRating')

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LIST_REQUEST:
      return action.meta.offset === 0 ? {} : state
    case FETCH_LIST_SUCCESS:
      if (action.payload.entities.tasks) {
        return {
          ...state,
          ...action.payload.entities.tasks,
        }
      }
      return {}
    case UPDATE_REQUEST:
      return update(state, action, { isUpdating: true })
    case UPDATE_SUCCESS:
      return update(state, action, {
        ...action.payload.entities.tasks[action.meta.taskId],
        isUpdating: false,
      })
    case UPDATE_FAILURE:
      return update(state, action, { isUpdating: false })
    case DELETE_REQUEST:
      return update(state, action, { isDeleting: true })
    case DELETE_SUCCESS:
      return omit(state, [action.meta.taskId])
    case DELETE_FAILURE:
      return update(state, action, { isDeleting: false })
    case FETCH_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.tasks,
      }
    case FETCH_REPORT_REQUEST:
      return update(state, action, { isReportFetching: true })
    case FETCH_REPORT_SUCCESS:
      return update(state, action, {
        isReportFetching: false,
        reportUrlAds: action.payload.ads.csvHref,
        reportUrlKeywords: action.payload.keywords && action.payload.keywords.csvHref,
      })
    case FETCH_REPORT_FAILURE:
      return update(state, action, { isReportFetching: false })
    case SET_MAX_ADS:
      writeMaxAds(action.meta.taskId, action.value)
      return update(state, action, { maxAds: action.value })
    case SET_MIN_RATING:
      writeMinRating(action.meta.taskId, action.value)
      return update(state, action, { minRating: action.value })
    default:
      return state
  }
}

const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_LIST_REQUEST:
      return action.meta.offset === 0 ? [] : state
    case FETCH_LIST_SUCCESS:
      return union(state, action.payload.result)
    case DELETE_SUCCESS:
      return state.filter(id => id !== action.meta.taskId)
    case FETCH_INFO_SUCCESS:
      return union(state, [action.payload.result])
    default:
      return state
  }
}

const isCreating = (state = false, action) => {
  switch (action.type) {
    case CREATE_REQUEST:
      return true
    case CREATE_SUCCESS:
    case CREATE_FAILURE:
      return false
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_LIST_REQUEST:
      return true
    case FETCH_LIST_SUCCESS:
    case FETCH_LIST_FAILURE:
      return false
    default:
      return state
  }
}

const offset = (state = 0, action) => {
  switch (action.type) {
    case FETCH_LIST_SUCCESS:
      return action.meta.offset
    default:
      return state
  }
}

const total = (state = 0, action) => {
  switch (action.type) {
    case FETCH_LIST_SUCCESS:
      return action.meta.total
    default:
      return state
  }
}

export default combineReducers({
  byId,
  ids,
  isCreating,
  isFetching,
  offset,
  total,
})
