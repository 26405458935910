import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'

const makeOptions = items =>
  items && items.map((item, idx) => ({ key: idx, value: item, text: item }))

class TagsListField extends Component {
  componentDidMount() {
    if (!this.props.items || !this.props.items.length) {
      this.props.fetchList()
    }
  }

  render() {
    const { input, items, isFetching, placeholder, maxSelected } = this.props

    return (
      <Dropdown
        fluid
        selection
        search
        multiple
        loading={isFetching}
        options={makeOptions(items) || []}
        value={input.value || []}
        placeholder={placeholder}
        onChange={(e, { value }) => {
          if (value && value.length <= maxSelected) {
            input.onChange(value)
          }
        }}
      />
    )
  }
}

TagsListField.defaultProps = {
  items: [],
  placeholder: 'Выберите значения...',
  maxSelected: 3,
}

TagsListField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.string),
  isFetching: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  maxSelected: PropTypes.number,
  fetchList: PropTypes.func.isRequired,
}

export default TagsListField
