import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Menu, Image } from 'semantic-ui-react'
import Breadcrumbs from './components/Breadcrumbs'
import LoginButton from './components/LoginButton'
import siteLogo from '../../../assets/img/logo.png'
import * as routes from '../../../constants/routes'
import * as pageNames from '../../../constants/page-names'

const HeaderWrap = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 80px;

  & .ui.image {
    top: 15px;
    margin-right: 2%;
    flex-shrink: 0;
  }
`

const HeaderControlsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 350px;
  margin-left: auto;

  .btn-auth {
    margin-left: auto !important;
  }
`

const getPageName = match => {
  switch (match.path) {
    case '/':
      return pageNames.MAIN
    case routes.TASKS_LIST_PAGE:
      return pageNames.TASKS_LIST
    case routes.NEWKEYWORDS_PAGE:
      return pageNames.NEWKEYWORDS
    case routes.EXCLUSIONS_PAGE:
      return pageNames.EXCLUSIONS
    case routes.TASK_PAGE:
      return pageNames.TASK
    // no default
  }

  throw new Error(`Unexpected path: ${match.path}`)
}

const HeaderMenu = ({ match: { params }, pageName }) => {
  const { projectId, taskId } = params
  let path
  let content

  if (!taskId || pageName === pageNames.NEWKEYWORDS) {
    return null
  }

  switch (pageName) {
    case pageNames.EXCLUSIONS:
      path = `/${projectId}/${taskId}`
      content = pageNames.TASK
      break
    case pageNames.TASK:
      path = `/${projectId}/${taskId}/exclusions/titles`
      content = pageNames.EXCLUSIONS
      break
    default:
      throw new Error('Wrong pageName provided to HeaderMenu')
  }

  return (
    <Menu className="header-menu">
      <Menu.Item as={Link} to={path} content={content} />
    </Menu>
  )
}

const Header = ({ match }) => {
  const pageName = getPageName(match)

  return (
    <HeaderWrap>
      <Image as={Link} to="/" src={siteLogo} alt="Dasha" className="logo" />
      <Breadcrumbs match={match} />
      <HeaderControlsWrap>
        <HeaderMenu match={match} pageName={pageName} />
        <LoginButton />
      </HeaderControlsWrap>
    </HeaderWrap>
  )
}

HeaderMenu.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  pageName: PropTypes.string.isRequired,
}

Header.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
}

export default Header
