import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Icon } from 'semantic-ui-react'
import { ErrorMessage } from 'pannier'
import extractPrice from '../../../../../../../utils/extractPrice'

const PriceFieldWrap = styled.div`
  max-width: 200px;

  input {
    width: 100% !important;
  }
`

const PriceField = ({
  input: { value, onChange },
  meta: { touched, error },
  placeholder,
  ...props
}) => (
  <PriceFieldWrap>
    <Form.Input
      placeholder={placeholder === undefined ? 'Введите цену...' : placeholder}
      value={value}
      onChange={(e, { value }) => onChange(value)}
      onBlur={e => {
        const { value } = e.target
        const price = extractPrice(value)

        if (price) {
          onChange(price)
        }
      }}
      icon={value && <Icon name="remove" link onClick={() => onChange(null)} />}
      error={touched && !!error}
      {...props}
    />
    {touched && error && <ErrorMessage error={error} />}
  </PriceFieldWrap>
)

PriceField.defaultProps = {
  placeholder: undefined,
}

PriceField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }).isRequired,
  placeholder: PropTypes.string,
}

export default PriceField
