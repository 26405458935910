import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Label, Menu, Ref } from 'semantic-ui-react'

const KeywordsListWrap = styled.div`
  position: relative;
  padding-bottom: 2px;
  height: calc(100vh - 300px);
  overflow-x: hidden;
  overflow-y: auto;

  .keywords-list {
    width: 100% !important;
  }

  @media (min-width: 1662px) {
    height: calc(100vh - 250px);
  }
`

class KeywordsList extends Component {
  componentDidMount() {
    if (this.activeElement) {
      const {
        activeElement,
        activeElement: { offsetParent },
      } = this
      offsetParent.scrollTop =
        activeElement.offsetTop -
        Math.floor(offsetParent.clientHeight / 2) +
        Math.floor(activeElement.clientHeight / 2)
    }
  }

  setActiveElement = activeElement => {
    this.activeElement = activeElement
  }

  render() {
    const { keywords, selectedKeywordId, onItemClick } = this.props

    return (
      <KeywordsListWrap>
        <Menu vertical className="keywords-list">
          {keywords.map(item => {
            if (item.id === selectedKeywordId) {
              return (
                <Ref key={item.id} innerRef={this.setActiveElement}>
                  <Menu.Item active>
                    {item.keyword}
                    <Label>
                      {item.ads.total} | {item.ads.selected}
                    </Label>
                  </Menu.Item>
                </Ref>
              )
            }

            return (
              <Menu.Item key={item.id} onClick={() => onItemClick(item.id)}>
                {item.keyword}
                <Label>
                  {item.ads.total} | {item.ads.selected}
                </Label>
              </Menu.Item>
            )
          })}
        </Menu>
      </KeywordsListWrap>
    )
  }
}

KeywordsList.propTypes = {
  keywords: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      keyword: PropTypes.string.isRequired,
      ads: PropTypes.shape({
        total: PropTypes.number.isRequired,
        selected: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
  selectedKeywordId: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
}

KeywordsList.defaultProps = {
  selectedKeywordId: null,
}

export default KeywordsList
