import { camelizeKeys } from 'humps'
import { schema } from 'normalizr'

const normalizeKeys = entity => camelizeKeys(entity)

const exclusionsSchema = new schema.Entity(
  'byId',
  {},
  {
    processStrategy: entity => {
      const { id, rejected_at: rejectedAt, title, text } = entity

      return normalizeKeys({
        id,
        rejectedAt,
        text: title || text,
        isUpdating: false,
      })
    },
  },
)

export const EXCLUSIONS_ARRAY = [exclusionsSchema]
