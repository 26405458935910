import { combineReducers } from 'redux'
import * as types from './constants'

export * from './actions'
export * from './selectors'
export * from './constants'

const update = (state, changes) => ({
  ...state,
  ...changes,
})

const initialState = {
  list: [],
  isFetching: false,
}

const makeListReducer = (REQUEST, SUCCESS, FAILURE) => (state = initialState, action) => {
  switch (action.type) {
    case REQUEST:
      return update(state, { isFetching: true })
    case SUCCESS:
      return {
        list: action.payload,
        isFetching: false,
      }
    case FAILURE:
      return update(state, { isFetching: false })
    default:
      return state
  }
}

const cities = makeListReducer(
  types.FETCH_CITIES_LIST_REQUEST,
  types.FETCH_CITIES_LIST_SUCCESS,
  types.FETCH_CITIES_LIST_FAILURE,
)

const metro = makeListReducer(
  types.FETCH_METRO_LIST_REQUEST,
  types.FETCH_METRO_LIST_SUCCESS,
  types.FETCH_METRO_LIST_FAILURE,
)

export default combineReducers({
  cities,
  metro,
})
