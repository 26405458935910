import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { createTask } from '../../../../stores/tasks'
import TaskFormModal from './components/TaskFormModal'

const mapStateToProps = state => ({
  isUpdating: state.tasks.isCreating,
})

const mapDispatchToProps = (
  dispatch,
  {
    history,
    match: {
      params: { projectId },
    },
  },
) => ({
  onSubmit: task => {
    const callback = taskId => {
      history.replace(`/${projectId}/${taskId}/keywords/new`)
    }

    return dispatch(createTask(projectId, task, callback))
  },
})

const Decorator = props => <TaskFormModal {...props} />

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Decorator),
)
