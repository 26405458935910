import { connect } from 'react-redux'
import TaskControls from './components/TaskControls'
import { showModal } from '../../../../../../stores/modal'
import { deleteTask, getTask } from '../../../../../../stores/tasks'
import * as modalTypes from '../../../../../../constants/modal-types'

const mapStateToProps = (state, { taskId }) => {
  const task = getTask(state, taskId)

  return {
    isDeleting: task ? task.isDeleting : false,
  }
}

const mapDispatchToProps = (dispatch, { taskId }) => ({
  onDeleteClick: () => {
    dispatch(
      showModal(modalTypes.CONFIRM, {
        content: 'Вы действительно хотите удалить эту задачу?',
        onConfirm: () => dispatch(deleteTask(taskId)),
      }),
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskControls)
