import { createSelector } from 'reselect'
import sortBy from 'lodash/sortBy'
import * as filters from '../../constants/keywords-filter'

const getKeywordsById = state => state.keywords.byId

export const isFetching = state => state.keywords.isFetching

export const getKeywords = createSelector(
  getKeywordsById,
  keywords => sortBy(Object.values(keywords), ['keyword']),
)

const getEmptyKeywords = createSelector(
  getKeywords,
  keywords => keywords.filter(x => x.ads.total === 0),
)
const getReadyKeywords = createSelector(
  getKeywords,
  keywords => keywords.filter(x => x.ads.selected > 0),
)
const getAccessibleKeywords = createSelector(
  getKeywords,
  keywords => keywords.filter(x => x.ads.total > 0 && x.ads.selected < 3),
)

export const getVisibleKeywords = (state, filter) => {
  switch (filter) {
    case filters.FILTER_ALL:
      return getKeywords(state)
    case filters.FILTER_EMPTY:
      return getEmptyKeywords(state)
    case filters.FILTER_READY:
      return getReadyKeywords(state)
    case filters.FILTER_ACCESSIBLE:
      return getAccessibleKeywords(state)
    default:
      throw new Error(`Unknown filter: ${filter}.`)
  }
}

export const getKeyword = createSelector(
  getKeywordsById,
  (_, keywordId) => keywordId,
  (keywords, id) => keywords[id],
)

export const isKeywordUpdating = createSelector(
  getKeyword,
  keyword => keyword && keyword.isUpdating,
)

export const getNextKeyword = (state, filter, keywordId) => {
  const visibleKeywords = getVisibleKeywords(state, filter)
  const idx = visibleKeywords.findIndex(item => item.id === keywordId)

  if (idx === visibleKeywords.length - 1) {
    return null
  }

  return visibleKeywords[idx + 1]
}
