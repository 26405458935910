import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import qs from 'query-string'
import isString from 'lodash/isString'
import uniqueId from 'lodash/uniqueId'
import styled from 'styled-components'
import Tooltip from '../../../../components/Tooltip'
import WithOpenModal from '../../../../components/WithOpenModal'
import getTagName from '../../../../../utils/getTagName'
import { TAG_SETTINGS_FORM } from '../../../../../constants/modal-types'
import { PALE_YELLOW } from '../../../../../styles/variables'

const StyledAdText = styled.div`
  .tag {
    padding: 0 2px;
    background: ${PALE_YELLOW};
    border-radius: 3px;
  }
`

/* eslint-disable react/no-array-index-key */
const AdText = ({
  chunks,
  match: {
    params: { taskId },
  },
}) => {
  if (isString(chunks)) {
    return chunks
  }

  const keywordId = qs.parse(window.location.search).id
  const tooltipId = uniqueId('ad-text-')

  return (
    <StyledAdText>
      {chunks.map((chunk, idx) => {
        if (isString(chunk)) {
          return chunk
        }

        const tagName = getTagName(chunk.tag)

        if (!tagName) {
          return chunk.value
        }

        return (
          <Fragment key={idx}>
            {chunk.tag !== chunk.value ? (
              <span className="tag" data-for={tooltipId} data-tip={chunk.tag}>
                {chunk.value}
              </span>
            ) : (
              <WithOpenModal>
                {openModal => (
                  <button
                    className="tag"
                    type="button"
                    data-for={tooltipId}
                    data-tip={chunk.tag}
                    onClick={() =>
                      openModal(TAG_SETTINGS_FORM, {
                        taskId,
                        keywordId,
                        availableTags: [tagName],
                      })
                    }
                  >
                    {chunk.value}
                  </button>
                )}
              </WithOpenModal>
            )}
          </Fragment>
        )
      })}
      <Tooltip id={tooltipId} />
    </StyledAdText>
  )
}
/* eslint-enable react/no-array-index-key */

AdText.propTypes = {
  chunks: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]),
    ),
  ]).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      taskId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

const AdTextWithoutRouter = AdText

export { AdTextWithoutRouter }

export default withRouter(AdText)
