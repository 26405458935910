import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Container, Header, Image } from 'semantic-ui-react'
import rollbar from '../../rollbar'
import logo from '../../assets/img/logo.png'

const ErrorMessageWrap = styled.div`
  margin: 0 auto;
  margin-top: 150px;
  width: 50%;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #9f3a38;

  & .ui.image {
    padding: 6px 11px;
    margin-bottom: 20px;
    border-radius: 26px;
    box-shadow: inset 0 0 25px rgba(219, 40, 40, 0.15), 0 -6px 25px rgba(219, 40, 40, 0.15);
    transition: box-shadow 0.5s;

    &:hover {
      box-shadow: inset 0 -1px 30px rgba(219, 40, 40, 0.5), 0 -8px 25px rgba(219, 40, 40, 0.5);
    }
  }
`

/* eslint-disable react/prop-types */
class ErrorHandler extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
    }
  }

  reset = () => {
    this.setState({ error: null })
  }

  componentDidCatch(error) {
    this.setState({
      error,
    })

    rollbar.error('Error while rendering components', error)
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorMessageWrap>
          <Image as={Link} to="/" src={logo} onClick={this.reset} />
          <Container textAlign="center">
            <Header color="red">Oops!</Header>
            <p>Мы пытались нарисовать красивую страницу, но что-то пошло не так.</p>
            <p>
              При повторении ошибки, пожалуйста, обратитесь за помощью к администратору сервиса.
            </p>
          </Container>
        </ErrorMessageWrap>
      )
    }

    return this.props.children
  }
}

export default ErrorHandler
