import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import { AuthPage } from 'auth-component'
import { resetErrorsState, getRequestError } from '../../stores/requestErrors'
import RequestErrorMessage from './RequestErrorMessage'
import getMessage from '../../messages'

const ERROR_STATUSES = [401, 403, 404]

const checkStatus = error => ERROR_STATUSES.includes(error.status)

const mapStateToProps = (state, { types }) => {
  const errors = types
    .map(type => getRequestError(state, type))
    .filter(error => error && checkStatus(error))
  const error = errors[0]

  return {
    error,
    failingAction: error && error.rsaa,
  }
}

const dispatch = obj => obj

const AuthModal = ({ isOpen, close }) => {
  const location = { state: { from: window.location.pathname } }

  return (
    <Modal size="tiny" open={isOpen}>
      <Modal.Content>
        <AuthPage modal onSubmit={close} location={location} />
      </Modal.Content>
    </Modal>
  )
}

class RequestError extends Component {
  state = {
    isModalOpen: true,
  }

  componentDidMount() {
    this.props.resetErrorsState(this.props.types)
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.error &&
      this.props.error &&
      this.props.error.status === 401 &&
      this.props.failingAction
    ) {
      this.openModal()
    }
  }

  componentWillUnmount() {
    this.props.resetErrorsState(this.props.types)
  }

  openModal = () => this.setState({ isModalOpen: true })

  closeModal = () => {
    if (this.props.failingAction) {
      this.props.dispatch(this.props.failingAction)
    }

    this.props.resetErrorsState(this.props.types)
    this.setState({ isModalOpen: false })
  }

  render() {
    const { error } = this.props

    if (error && error.status) {
      switch (error.status) {
        case 401:
          return (
            <Fragment>
              {this.props.children}
              <AuthModal isOpen={this.state.isModalOpen} close={this.closeModal} />
            </Fragment>
          )
        case 403:
          return <RequestErrorMessage message={getMessage('request_errors.403')} />
        case 404:
          return <RequestErrorMessage message={getMessage('request_errors.404')} />
        // no default
      }
    }

    return this.props.children
  }
}

AuthModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
}

RequestError.defaultProps = {
  error: null,
  failingAction: null,
}

RequestError.propTypes = {
  resetErrorsState: PropTypes.func.isRequired,
  error: PropTypes.shape({
    status: PropTypes.number,
  }),
  failingAction: PropTypes.objectOf(PropTypes.any),
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.element.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  { resetErrorsState, dispatch },
)(RequestError)
