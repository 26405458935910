import { RSAA, getJSON } from 'redux-api-middleware'
import { normalize } from 'normalizr'
import debounce from 'lodash/debounce'
import nprogress from 'nprogress'
import * as schemas from './schemas'
import { ADS_PAGE_SIZE } from '../../constants'
import * as types from './index'
import * as selectedStatuses from '../../constants/selected-statuses'
import { getAd, getTitle, getText, getSelectedAds } from './selectors'
import { fetchKeywords } from '../keywords/actions'

const hasNextPage = action => action && action.meta && action.meta.nextPage

const debouncedFetchKeywords = debounce(
  (dispatch, taskId, page) => dispatch(fetchKeywords(taskId, page)),
  500,
)

// sync actions

export const setFilter = (taskId, filter) => ({
  type: types.SET_FILTER,
  taskId,
  filter,
})

export const selectAdsMenu = item => ({
  type: types.SET_MENU_ITEM,
  item,
})

export const selectTitle = (id, status) => {
  if (status && status !== selectedStatuses.SELECTED && status !== selectedStatuses.FROZEN) {
    throw new Error('wrong status')
  }

  return {
    type: types.SELECT_TITLE,
    ids: Array.isArray(id) ? id : [id],
    status,
  }
}

export const selectText = (id, status) => {
  if (status && status !== selectedStatuses.SELECTED && status !== selectedStatuses.FROZEN) {
    throw new Error('wrong status')
  }

  return {
    type: types.SELECT_TEXT,
    ids: Array.isArray(id) ? id : [id],
    status,
  }
}

export const deselectTitle = id => ({
  type: types.DESELECT_TITLE,
  id,
})

export const deselectText = id => ({
  type: types.DESELECT_TEXT,
  id,
})

export const hideAd = id => ({
  type: types.HIDE_AD,
  id,
})

export const setShowTags = value => ({
  type: types.SET_SHOW_TAGS,
  value,
})

// async actions

let lastKeywordId = null

export const fetchAds = (taskId, keywordId, page = 1, pageSize = ADS_PAGE_SIZE) => {
  const offset = (page - 1) * pageSize
  const meta = {
    taskId,
    keywordId,
    currentPage: page,
  }

  if (page === 1) {
    lastKeywordId = keywordId
    nprogress.start()
  }

  return (dispatch, getState) =>
    dispatch({
      [RSAA]: {
        endpoint: `/api/tasks/${taskId}/groups/${keywordId}/ads?limit=${pageSize}&offset=${offset}`,
        method: 'GET',
        credentials: 'same-origin',
        options: {
          authRequired: true,
        },
        bailout: !keywordId || !taskId || lastKeywordId !== keywordId,
        types: [
          {
            type: types.FETCH_LIST_REQUEST,
            meta,
          },
          {
            type: types.FETCH_LIST_SUCCESS,
            meta: (action, state, res) =>
              getJSON(res.clone()).then(json => ({
                ...meta,
                lastKeywordId,
                total: json.meta.total,
                nextPage: json.meta.total > offset + pageSize ? page + 1 : null,
              })),
            payload: (action, state, res) =>
              getJSON(res.clone()).then(json => normalize(json.data, schemas.AD_ARRAY)),
          },
          {
            type: types.FETCH_LIST_FAILURE,
            meta: {
              ...meta,
              lastKeywordId,
            },
          },
        ],
      },
    })
      .then(action => {
        if (hasNextPage(action)) {
          if (action.meta.keywordId === lastKeywordId && action.type === types.FETCH_LIST_SUCCESS) {
            nprogress.set(offset / action.meta.total)
          }
          return dispatch(fetchAds(taskId, keywordId, action.meta.nextPage, pageSize))
        }
        nprogress.done()
        return action
      })
      .then(action => {
        if (!hasNextPage(action) && lastKeywordId === keywordId) {
          const titleIds = getSelectedAds(getState()).map(ad => ad.title.id)
          const textIds = getSelectedAds(getState()).map(ad => ad.text.id)

          if (titleIds.length) {
            dispatch(selectTitle(titleIds, selectedStatuses.FROZEN))
          }

          if (textIds.length) {
            dispatch(selectText(textIds, selectedStatuses.FROZEN))
          }
        }

        return action
      })
}

export const selectAd = (taskId, keywordId, adId) => {
  const meta = {
    adId,
    keywordId,
  }

  return (dispatch, getState) =>
    dispatch({
      [RSAA]: {
        endpoint: `/api/tasks/${taskId}/groups/${keywordId}/ads/${adId}/select`,
        method: 'PUT',
        credentials: 'same-origin',
        options: {
          authRequired: true,
        },
        bailout: state => getAd(state, adId).isChanging,
        types: [
          {
            type: types.SELECT_AD_REQUEST,
            meta,
          },
          {
            type: types.SELECT_AD_SUCCESS,
            meta,
          },
          {
            type: types.SELECT_AD_FAILURE,
            meta,
          },
        ],
      },
    }).then(action => {
      if (action && action.type === types.SELECT_AD_SUCCESS) {
        dispatch(selectTitle(getState().ads.adsById[adId].title, selectedStatuses.FROZEN))
        dispatch(selectText(getState().ads.adsById[adId].text, selectedStatuses.FROZEN))
      }
    })
}

export const deselectAd = (taskId, keywordId, adId) => {
  const meta = {
    adId,
    keywordId,
  }

  return (dispatch, getState) =>
    dispatch({
      [RSAA]: {
        endpoint: `/api/tasks/${taskId}/groups/${keywordId}/ads/${adId}/select`,
        method: 'DELETE',
        credentials: 'same-origin',
        options: {
          authRequired: true,
        },
        bailout: state => getAd(state, adId).isChanging,
        types: [
          {
            type: types.DESELECT_AD_REQUEST,
            meta,
          },
          {
            type: types.DESELECT_AD_SUCCESS,
            meta,
          },
          {
            type: types.DESELECT_AD_FAILURE,
            meta,
          },
        ],
      },
    }).then(action => {
      if (action && action.type === types.DESELECT_AD_SUCCESS) {
        const state = getState()
        const titleIdsInSelectedAds = getSelectedAds(state).map(ad => ad.title.id)
        const textIdsInSelectedAds = getSelectedAds(state).map(ad => ad.text.id)
        const titleId = state.ads.adsById[adId].title
        const textId = state.ads.adsById[adId].text

        if (titleIdsInSelectedAds.indexOf(titleId) === -1) {
          dispatch(selectTitle(titleId, selectedStatuses.SELECTED))
        }

        if (textIdsInSelectedAds.indexOf(textId) === -1) {
          dispatch(selectText(textId, selectedStatuses.SELECTED))
        }
      }

      return action
    })
}

export const rejectTitle = (taskId, titleId) => (dispatch, getState) => {
  const state = getState()
  const title = getTitle(state, titleId)
  const meta = {
    titleId,
  }

  return dispatch({
    [RSAA]: {
      endpoint: `/api/tasks/${taskId}/rejected-titles`,
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      options: {
        authRequired: true,
      },
      bailout: state => getTitle(state, titleId).isRejecting,
      body: JSON.stringify({
        data: [title.text],
      }),
      types: [
        {
          type: types.REJECT_TITLE_REQUEST,
          meta,
        },
        {
          type: types.REJECT_TITLE_SUCCESS,
          meta,
        },
        {
          type: types.REJECT_TITLE_FAILURE,
          meta,
        },
      ],
    },
  }).then(action => {
    if (action && action.type === types.REJECT_TITLE_SUCCESS) {
      debouncedFetchKeywords(dispatch, taskId)
    }
  })
}

export const rejectText = (taskId, textId) => (dispatch, getState) => {
  const state = getState()
  const text = getText(state, textId)
  const meta = {
    textId,
  }

  return dispatch({
    [RSAA]: {
      endpoint: `/api/tasks/${taskId}/rejected-texts`,
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      options: {
        authRequired: true,
      },
      bailout: state => getText(state, textId).isRejecting,
      body: JSON.stringify({
        data: [text.text],
      }),
      types: [
        {
          type: types.REJECT_TEXT_REQUEST,
          meta,
        },
        {
          type: types.REJECT_TEXT_SUCCESS,
          meta,
        },
        {
          type: types.REJECT_TEXT_FAILURE,
          meta,
        },
      ],
    },
  }).then(action => {
    if (action && action.type === types.REJECT_TEXT_SUCCESS) {
      debouncedFetchKeywords(dispatch, taskId)
    }
  })
}
