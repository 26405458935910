import { combineReducers } from 'redux'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import { saveFilter, loadAllFilters } from '../../utils/adsFilter'
import * as adsMenu from '../../constants/ads-menu'
import * as selectedStatuses from '../../constants/selected-statuses'

export * from './actions'
export * from './selectors'

const prefix = '@ads/'

export const SET_MENU_ITEM = `${prefix}SET_MENU_ITEM`

export const SET_FILTER = `${prefix}SET_FILTER`

export const SET_SHOW_TAGS = `${prefix}SET_SHOW_TAGS`

export const FETCH_LIST_REQUEST = `${prefix}FETCH_LIST_REQUEST`
export const FETCH_LIST_SUCCESS = `${prefix}FETCH_LIST_SUCCESS`
export const FETCH_LIST_FAILURE = `${prefix}FETCH_LIST_FAILURE`

export const SELECT_AD_REQUEST = `${prefix}SELECT_AD_REQUEST`
export const SELECT_AD_SUCCESS = `${prefix}SELECT_AD_SUCCESS`
export const SELECT_AD_FAILURE = `${prefix}SELECT_AD_FAILURE`

export const DESELECT_AD_REQUEST = `${prefix}DESELECT_AD_REQUEST`
export const DESELECT_AD_SUCCESS = `${prefix}DESELECT_AD_SUCCESS`
export const DESELECT_AD_FAILURE = `${prefix}DESELECT_AD_FAILURE`

export const HIDE_AD = `${prefix}HIDE_AD`

export const SELECT_TITLE = `${prefix}SELECT_TITLE`
export const SELECT_TEXT = `${prefix}SELECT_TEXT`

export const DESELECT_TITLE = `${prefix}DESELECT_TITLE`
export const DESELECT_TEXT = `${prefix}DESELECT_TEXT`

export const REJECT_TITLE_REQUEST = `${prefix}REJECT_TITLE_REQUEST`
export const REJECT_TITLE_SUCCESS = `${prefix}REJECT_TITLE_SUCCESS`
export const REJECT_TITLE_FAILURE = `${prefix}REJECT_TITLE_FAILURE`

export const REJECT_TEXT_REQUEST = `${prefix}REJECT_TEXT_REQUEST`
export const REJECT_TEXT_SUCCESS = `${prefix}REJECT_TEXT_SUCCESS`
export const REJECT_TEXT_FAILURE = `${prefix}REJECT_TEXT_FAILURE`

const updateAd = (adsById, action, changes) => {
  const { adId } = action.meta
  return {
    ...adsById,
    [adId]: {
      ...adsById[adId],
      ...changes,
    },
  }
}

const getSelectedTexts = (state, ids, status) =>
  ids.reduce((prev, id) => {
    if (!(id in state)) {
      return prev
    }

    /* eslint-disable no-param-reassign */
    prev[id] = {
      ...state[id],
      status,
    }
    /* eslint-enable no-param-reassign */

    return prev
  }, {})

// Reducers

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_LIST_REQUEST:
      return true
    case FETCH_LIST_SUCCESS:
    case FETCH_LIST_FAILURE: {
      const { lastKeywordId, keywordId } = action.meta
      if (lastKeywordId !== keywordId) {
        return state
      }
      return false
    }
    default:
      return state
  }
}

const activeMenuItem = (state = adsMenu.ADS, action) => {
  switch (action.type) {
    case FETCH_LIST_SUCCESS:
      return adsMenu.ADS
    case SET_MENU_ITEM:
      return action.item
    default:
      return state
  }
}

const adsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LIST_REQUEST:
      if (action.meta.currentPage === 1) {
        return {}
      }
      return state
    case FETCH_LIST_SUCCESS:
      if (action.meta.lastKeywordId !== action.meta.keywordId) {
        return state
      }

      if (action.meta.currentPage === 1) {
        return action.payload.entities.ads || {}
      }

      if (action.payload.entities.ads) {
        return {
          ...state,
          ...action.payload.entities.ads,
        }
      }

      return state
    case FETCH_LIST_FAILURE:
      return {}
    case SELECT_AD_REQUEST:
    case DESELECT_AD_REQUEST:
      return updateAd(state, action, {
        isChanging: true,
      })
    case SELECT_AD_SUCCESS:
      return updateAd(state, action, {
        isChanging: false,
        selected: true,
      })
    case DESELECT_AD_SUCCESS:
      return updateAd(state, action, {
        isChanging: false,
        selected: false,
      })
    case SELECT_AD_FAILURE:
    case DESELECT_AD_FAILURE:
      return updateAd(state, action, {
        isChanging: false,
      })
    case REJECT_TITLE_SUCCESS:
      return omitBy(state, ad => ad.title === action.meta.titleId)
    case REJECT_TEXT_SUCCESS:
      return omitBy(state, ad => ad.text === action.meta.textId)
    default:
      return state
  }
}

const titlesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LIST_REQUEST:
      if (action.meta.currentPage === 1) {
        return {}
      }
      return state
    case FETCH_LIST_SUCCESS:
      if (action.meta.lastKeywordId !== action.meta.keywordId) {
        return state
      }

      if (action.meta.currentPage === 1) {
        return action.payload.entities.titles || {}
      }

      if (action.payload.entities.titles) {
        return {
          ...state,
          ...action.payload.entities.titles,
        }
      }

      return state
    case FETCH_LIST_FAILURE:
      return {}
    case REJECT_TITLE_REQUEST:
      return {
        ...state,
        [action.meta.titleId]: {
          ...state[action.meta.titleId],
          isRejecting: true,
        },
      }
    case REJECT_TITLE_SUCCESS:
      return omit(state, action.meta.titleId)
    case REJECT_TITLE_FAILURE:
      return {
        ...state,
        [action.meta.titleId]: {
          ...state[action.meta.titleId],
          isRejecting: false,
        },
      }
    case SELECT_TITLE:
      return {
        ...state,
        ...getSelectedTexts(state, action.ids, action.status || selectedStatuses.SELECTED),
      }
    case DESELECT_TITLE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          status: selectedStatuses.UNSELECTED,
        },
      }
    default:
      return state
  }
}

const textsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LIST_REQUEST:
      if (action.meta.currentPage === 1) {
        return {}
      }
      return state
    case FETCH_LIST_SUCCESS:
      if (action.meta.lastKeywordId !== action.meta.keywordId) {
        return state
      }

      if (action.meta.currentPage === 1) {
        return action.payload.entities.texts || {}
      }

      if (action.payload.entities.texts) {
        return {
          ...state,
          ...action.payload.entities.texts,
        }
      }

      return state
    case FETCH_LIST_FAILURE:
      return {}
    case REJECT_TEXT_REQUEST:
      return {
        ...state,
        [action.meta.textId]: {
          ...state[action.meta.textId],
          isRejecting: true,
        },
      }
    case REJECT_TEXT_SUCCESS:
      return omit(state, action.meta.textId)
    case REJECT_TEXT_FAILURE:
      return {
        ...state,
        [action.meta.textId]: {
          ...state[action.meta.textId],
          isRejecting: false,
        },
      }
    case SELECT_TEXT:
      return {
        ...state,
        ...getSelectedTexts(state, action.ids, action.status || selectedStatuses.SELECTED),
      }
    case DESELECT_TEXT:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          status: selectedStatuses.UNSELECTED,
        },
      }
    default:
      return state
  }
}

const hiddenAds = (state = {}, action) => {
  switch (action.type) {
    case HIDE_AD: {
      return {
        ...state,
        [action.id]: true,
      }
    }
    default:
      return state
  }
}

const showTags = (state = false, action) => {
  switch (action.type) {
    case SET_SHOW_TAGS:
      return action.value
    default:
      return state
  }
}

const filters = (state = loadAllFilters(), action) => {
  switch (action.type) {
    case SET_FILTER:
      saveFilter(action.taskId, action.filter)
      return { ...state, [action.taskId]: action.filter }
    default:
      return state
  }
}

export default combineReducers({
  isFetching,
  activeMenuItem,
  adsById,
  titlesById,
  textsById,
  hiddenAds,
  showTags,
  filters,
})
