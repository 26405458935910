import { makeTrans } from 'pannier'

export default makeTrans({
  'request_errors.403': 'К сожалению, у Вас нет доступа к этой странице.',
  'request_errors.404': 'Данные не найдены.',
  'form.submit.unk_error':
    'Извините, при обработке формы возникла неизвестная ошибка. Повторите попытку и обратитесь в службу поддержки, если ошибка сохранится.',
  'form.submit.error': 'Пожалуйста, исправьте ошибки и повторите отправку данных.',
  'form.error.not_number': 'Значение должно быть числом',
  'form.error.not_integer': 'Значение должно быть целым числом',
  'form.error.greater_than': ({ value, comparedTo }) =>
    `Значение ${value} не должно быть больше чем ${comparedTo}`,
  'form.error.less_than': ({ value, comparedTo }) =>
    `Значение ${value} не должно быть меньше чем ${comparedTo}`,
})
