import _omitBy from 'lodash/omitBy'

export * from './actions'
export * from './selectors'

const prefix = '@exclusions/'

export const FETCH_TITLE_EXCLUSIONS_REQUEST = `${prefix}FETCH_TITLE_EXCLUSIONS_REQUEST`
export const FETCH_TITLE_EXCLUSIONS_SUCCESS = `${prefix}FETCH_TITLE_EXCLUSIONS_SUCCESS`
export const FETCH_TITLE_EXCLUSIONS_FAILURE = `${prefix}FETCH_TITLE_EXCLUSIONS_FAILURE`

export const FETCH_TEXT_EXCLUSIONS_REQUEST = `${prefix}FETCH_TEXT_EXCLUSIONS_REQUEST`
export const FETCH_TEXT_EXCLUSIONS_SUCCESS = `${prefix}FETCH_TEXT_EXCLUSIONS_SUCCESS`
export const FETCH_TEXT_EXCLUSIONS_FAILURE = `${prefix}FETCH_TEXT_EXCLUSIONS_FAILURE`

export const UNREJECT_TITLE_REQUEST = `${prefix}UNREJECT_TITLE_REQUEST`
export const UNREJECT_TITLE_SUCCESS = `${prefix}UNREJECT_TITLE_SUCCESS`
export const UNREJECT_TITLE_FAILURE = `${prefix}UNREJECT_TITLE_FAILURE`

export const UNREJECT_TEXT_REQUEST = `${prefix}UNREJECT_TEXT_REQUEST`
export const UNREJECT_TEXT_SUCCESS = `${prefix}UNREJECT_TEXT_SUCCESS`
export const UNREJECT_TEXT_FAILURE = `${prefix}UNREJECT_TEXT_FAILURE`

const getTotalPages = (limit, total) => Math.floor(total / limit) + 1

const getCurrentPage = (offset, limit, totalPages) => {
  const currentPage = Math.floor(offset / limit) + 1
  return currentPage > totalPages ? totalPages : currentPage
}

const getSortColumn = sort => {
  const columnName = sort[0] === '-' ? sort.slice(1) : sort
  switch (columnName) {
    case 'title':
    case 'text':
      return 'text'
    case 'rejected_at':
      return 'rejectedAt'
    // no default
  }

  return null
}

const getSortDirection = sort => (sort[0] === '-' ? 'descending' : 'ascending')

const initialTitleExclusionsState = {
  byId: {},
  isFetching: false,
  currentPage: 1,
  totalPages: 1,
  totalItems: 0,
  sortColumn: 'rejectedAt',
  sortDirection: 'descending',
}

export const titleExclusions = (state = initialTitleExclusionsState, action) => {
  switch (action.type) {
    case FETCH_TITLE_EXCLUSIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_TITLE_EXCLUSIONS_SUCCESS: {
      const { entities, offset, limit, total, sort } = action.payload
      const totalPages = getTotalPages(limit, total)
      return {
        ...state,
        ...entities,
        isFetching: false,
        currentPage: getCurrentPage(offset, limit, totalPages),
        totalPages,
        totalItems: total,
        sortColumn: getSortColumn(sort),
        sortDirection: getSortDirection(sort),
      }
    }
    case FETCH_TITLE_EXCLUSIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    case UNREJECT_TITLE_REQUEST:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.titleId]: {
            ...state.byId[action.meta.titleId],
            isUpdating: true,
          },
        },
      }
    case UNREJECT_TITLE_SUCCESS:
      return {
        ...state,
        byId: _omitBy(state.byId, item => item.id === action.meta.titleId),
        totalItems: state.totalItems - 1,
      }
    case UNREJECT_TITLE_FAILURE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.titleId]: {
            ...state.byId[action.meta.titleId],
            isUpdating: false,
          },
        },
      }
    default:
      return state
  }
}

const initialTextExclusionsState = {
  byId: {},
  isFetching: false,
  currentPage: 1,
  totalPages: 1,
  totalItems: 0,
  sortColumn: 'rejectedAt',
  sortDirection: 'descending',
}

export const textExclusions = (state = initialTextExclusionsState, action) => {
  switch (action.type) {
    case FETCH_TEXT_EXCLUSIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_TEXT_EXCLUSIONS_SUCCESS: {
      const { entities, offset, limit, total, sort } = action.payload
      const totalPages = getTotalPages(limit, total)
      return {
        ...state,
        ...entities,
        isFetching: false,
        currentPage: getCurrentPage(offset, limit, totalPages),
        totalPages,
        totalItems: total,
        sortColumn: getSortColumn(sort),
        sortDirection: getSortDirection(sort),
      }
    }
    case FETCH_TEXT_EXCLUSIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    case UNREJECT_TEXT_REQUEST:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.textId]: {
            ...state.byId[action.meta.textId],
            isUpdating: true,
          },
        },
      }
    case UNREJECT_TEXT_SUCCESS:
      return {
        ...state,
        byId: _omitBy(state.byId, item => item.id === action.meta.textId),
        totalItems: state.totalItems - 1,
      }
    case UNREJECT_TEXT_FAILURE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.textId]: {
            ...state.byId[action.meta.textId],
            isUpdating: false,
          },
        },
      }
    default:
      return state
  }
}
