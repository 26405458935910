import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Dropdown } from 'semantic-ui-react'
import { downloadReport, getTask } from '../../../../../../../stores/tasks'

export const REPORT_ADS = 'ads'
export const REPORT_KEYWORDS = 'keywords'

const mapStateToProps = (state, { taskId }) => {
  const task = getTask(state, taskId) || {}

  return {
    urls: {
      [REPORT_ADS]: task.reportUrlAds,
      [REPORT_KEYWORDS]: task.reportUrlKeywords,
    },
    isFetching: task.isReportFetching,
  }
}

class DownloadReportButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      report: null,
    }
  }

  componentDidUpdate(prevProps) {
    const { report } = this.state
    const reportUrl = this.props.urls[report]
    if (reportUrl && reportUrl !== prevProps.urls[report]) {
      window.location.assign(reportUrl)
    }
  }

  downloadReport = (e, { report }) => {
    this.setState({ report })
    this.props.downloadReport(this.props.taskId)
  }

  render() {
    const { isFetching, isMenuItem } = this.props
    const buttonProps = {
      onClick: this.downloadReport,
      report: REPORT_ADS,
      icon: 'download',
      content: 'Скачать',
    }

    if (isMenuItem) {
      return <Dropdown.Item {...buttonProps} />
    }

    return (
      <Button.Group>
        <Button {...buttonProps} loading={isFetching} />
        <Dropdown button className="icon">
          <Dropdown.Menu>
            <Dropdown.Item onClick={this.downloadReport} report={REPORT_ADS}>
              Выбранные объявления
            </Dropdown.Item>
            <Dropdown.Item onClick={this.downloadReport} report={REPORT_KEYWORDS}>
              По все ключевым фразам
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Button.Group>
    )
  }
}

DownloadReportButton.defaultProps = {
  isFetching: false,
  isMenuItem: false,
}

DownloadReportButton.propTypes = {
  taskId: PropTypes.string.isRequired,
  downloadReport: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  isMenuItem: PropTypes.bool,
  urls: PropTypes.shape({
    [REPORT_ADS]: PropTypes.string,
    [REPORT_KEYWORDS]: PropTypes.string,
  }).isRequired,
}

export default connect(
  mapStateToProps,
  { downloadReport },
)(DownloadReportButton)
