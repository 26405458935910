import { RSAA } from 'redux-api-middleware'
import * as types from './index'

export const addKeywords = (task, keywords, redirect) => ({
  [RSAA]: {
    endpoint: `/api/tasks/${task}/groups`,
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    options: {
      authRequired: true,
    },
    body: JSON.stringify({
      data: keywords,
    }),
    types: [
      types.ADD_REQUEST,
      {
        type: types.ADD_SUCCESS,
        payload: () => {
          redirect()
        },
      },
      types.ADD_FAILURE,
    ],
  },
})
