import { createSelector } from 'reselect'

const getTasksById = state => state.tasks.byId
const getTasksIds = state => state.tasks.ids

export const isFetching = state => state.tasks.isFetching
export const getOffset = state => state.tasks.offset
export const getTotal = state => state.tasks.total

export const getTasks = createSelector(
  getTasksIds,
  getTasksById,
  (ids, tasks) => ids.map(id => tasks[id]),
)

export const getTask = createSelector(
  getTasksById,
  (state, taskId) => taskId,
  (tasks, taskId) => tasks[taskId],
)

export const isTaskUpdating = createSelector(
  getTask,
  task => task && task.isUpdating,
)
export const getMaxAds = createSelector(
  getTask,
  task => task && task.maxAds,
)
export const getMinRating = createSelector(
  getTask,
  task => task && task.minRating,
)
