import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Label } from 'semantic-ui-react'

const CountableItem = ({ active, children, count, onClick }) => (
  <MenuItem active={active} onClick={onClick}>
    {children}
    <Label>{count}</Label>
  </MenuItem>
)

CountableItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

CountableItem.defaultProps = {
  active: false,
}

export default CountableItem
