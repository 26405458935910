import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FieldArray, FormSection } from 'redux-form'
import { Form, Button, Header, Divider } from 'semantic-ui-react'
import { ErrorMessage } from 'pannier'
import CityField from './components/CityField'
import PriceField from './components/PriceField'
import ShopFieldArray from './components/ShopFieldArray'
import MetroField from './components/MetroField'
import DisField from './components/DisField'
import { validate } from '../../../../../../utils/tagEditFormUtils'
import * as tagNames from '../../../../../../constants/tag-names'
import { isNumber } from '../../../../../../utils/validators'

const TagField = ({ header, name, component, children, availableTags, ...props }) => {
  if (availableTags.includes(name)) {
    return (
      <Fragment>
        <Header as="h4">{header}</Header>
        {component ? <Field name={name} component={component} {...props} /> : children}
        <Divider />
      </Fragment>
    )
  }

  return null
}

TagField.defaultProps = {
  component: null,
  children: null,
}

TagField.propTypes = {
  header: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  component: PropTypes.func,
  children: PropTypes.element,
  availableTags: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const TagEditForm = ({ handleSubmit, availableTags, isUpdating, canSubmit, error }) => (
  <Form>
    <TagField
      header="Город"
      name={tagNames.CITY}
      component={CityField}
      availableTags={availableTags}
    />
    <TagField
      header="Цена"
      name={tagNames.PRICE}
      component={PriceField}
      availableTags={availableTags}
      validate={isNumber}
    />
    <TagField header="Название магазина" availableTags={availableTags} name={tagNames.SHOP}>
      <FieldArray name={tagNames.SHOP} component={ShopFieldArray} />
    </TagField>
    <TagField
      header="Метро"
      name={tagNames.METRO}
      component={MetroField}
      availableTags={availableTags}
    />
    <TagField header="Скидка, %" availableTags={availableTags} name={tagNames.DIS}>
      <FormSection name={tagNames.DIS} component={DisField} tagName={tagNames.DIS} />
    </TagField>
    <TagField header="Скидка" availableTags={availableTags} name={tagNames.DISCOUNT}>
      <FormSection name={tagNames.DISCOUNT} component={DisField} />
    </TagField>
    <ErrorMessage error={error} attached="bottom" />
    <Button content="Сохранить" disabled={!canSubmit} loading={isUpdating} onClick={handleSubmit} />
  </Form>
)

TagEditForm.defaultProps = {
  error: null,
}

TagEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  availableTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  isUpdating: PropTypes.bool.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

export default reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(TagEditForm)
