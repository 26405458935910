import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Header, Dropdown } from 'semantic-ui-react'
import debounce from 'lodash/debounce'
import InitialKeywords from './components/InitialKeywords'
import TagSettingsButton from './components/TagSettingsButton'
import MunusFilter from './components/MinusFilter'
import DownloadReportButton from './components/DownloadReportButton'

const StyledGroupName = styled.span`
  display: block;
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

class AdsHeader extends Component {
  state = { showMenu: false }

  handleResize = debounce(() => this.setState({ showMenu: document.body.clientWidth < 1350 }), 100)

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    const { taskId, keywordId, keyword } = this.props

    return (
      <div className="ads-heading">
        <Header as="h2" className="condensed">
          {keyword ? (
            <Fragment>
              <StyledGroupName>Группа: {keyword.keyword}</StyledGroupName>
              <InitialKeywords taskId={taskId} keyword={keyword} />
            </Fragment>
          ) : (
            '...'
          )}
        </Header>
        <div>
          {this.state.showMenu ? (
            <Dropdown trigger={<Button icon="ellipsis vertical" />} floating icon={null}>
              <Dropdown.Menu direction="left">
                <TagSettingsButton isMenuItem taskId={taskId} keywordId={keywordId} />
                <MunusFilter isMenuItem taskId={taskId} />
                <DownloadReportButton isMenuItem taskId={taskId} />
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Fragment>
              <TagSettingsButton taskId={taskId} keywordId={keywordId} />
              <MunusFilter taskId={taskId} />
              <DownloadReportButton taskId={taskId} />
            </Fragment>
          )}
        </div>
      </div>
    )
  }
}

AdsHeader.defaultProps = {
  keyword: null,
}

AdsHeader.propTypes = {
  taskId: PropTypes.string.isRequired,
  keywordId: PropTypes.string.isRequired,
  keyword: PropTypes.shape({
    initialKeywords: PropTypes.arrayOf(PropTypes.string),
    initialKeywordsCount: PropTypes.number,
  }),
}

export default AdsHeader
