import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { hideModal } from '../../stores/modal'
import * as modalTypes from '../../constants/modal-types'
import ConfirmModal from './components/ConfirmModal'
import CreateTaskForm from './components/CreateTaskForm'
import TagSettings from './components/TagSettings'

const MODAL_COMPONENTS = {
  [modalTypes.CONFIRM]: ConfirmModal,
  [modalTypes.CREATE_TASK_FORM]: CreateTaskForm,
  [modalTypes.TAG_SETTINGS_FORM]: TagSettings,
}

const ModalManager = ({ modalType, modalProps = {}, hideModal, ...props }) => {
  if (!modalType) {
    return null
  }

  const Modal = MODAL_COMPONENTS[modalType]
  const closeModal = () => hideModal(modalType)

  return <Modal {...modalProps} {...props} closeModal={closeModal} />
}

/* eslint-disable react/forbid-prop-types */
ModalManager.propTypes = {
  modalType: PropTypes.string,
  modalProps: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
}
/* eslint-enable react/forbid-prop-types */

ModalManager.defaultProps = {
  modalType: null,
  modalProps: {},
}

export default connect(
  state => state.modal,
  {
    hideModal,
  },
)(ModalManager)
