import * as keyStatuses from '../constants/newKeywordStatuses'

export function parseKeyword(input, isDouble) {
  let group = null
  let keyword = input
  let status = keyStatuses.NEW_KEYWORD_STATUS_OK
  const splitted = input.split('\t')

  if (!keyword.trim()) {
    status = keyStatuses.NEW_KEYWORD_STATUS_BLANK
  } else if (isDouble) {
    status = keyStatuses.NEW_KEYWORD_STATUS_DOUBLE
  }

  if (splitted.length === 2) {
    ;[group, keyword] = splitted
  } else if (splitted.length > 2) {
    status = keyStatuses.NEW_KEYWORD_STATUS_INVALID
  }

  return {
    group,
    keyword,
    status,
  }
}

export function getStat(keywords) {
  const stat = {
    [keyStatuses.NEW_KEYWORD_STATUS_OK]: 0,
    [keyStatuses.NEW_KEYWORD_STATUS_DOUBLE]: 0,
    [keyStatuses.NEW_KEYWORD_STATUS_INVALID]: 0,
  }

  keywords.forEach(item => {
    stat[item.status] += 1
  })

  return stat
}

export function translateStatus(status) {
  switch (status) {
    case keyStatuses.NEW_KEYWORD_STATUS_DOUBLE:
      return 'дубль'
    case keyStatuses.NEW_KEYWORD_STATUS_INVALID:
      return 'неизвестный формат'
    default:
      return status
  }
}

export default function getKeywords(str) {
  const keywords = str.split('\n')
  const existKeywords = {}
  const isDouble = keyword => {
    if (keyword in existKeywords) {
      return true
    }

    existKeywords[keyword] = true
    return false
  }

  return keywords
    .map((keyword, index) => ({
      line: index + 1,
      ...parseKeyword(keyword, isDouble(keyword)),
    }))
    .filter(item => item.status !== keyStatuses.NEW_KEYWORD_STATUS_BLANK)
}
