import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Button } from 'semantic-ui-react'

const MinusFilterForm = ({ className, onSubmit, onChange, value }) => (
  <Form onSubmit={onSubmit} className={className}>
    <Form.TextArea
      placeholder="Введите минус-слова..."
      rows={10}
      value={value}
      onChange={onChange}
    />
    <Button size="small" content="Сохранить" />
  </Form>
)

MinusFilterForm.propTypes = {
  className: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

const StyledMinusFilterForm = styled(MinusFilterForm)`
  padding-top: 15px;

  textarea {
    resize: none !important;
  }
`

export default StyledMinusFilterForm
