import { RSAA, getJSON } from 'redux-api-middleware'
import { normalize } from 'normalizr'
import { decamelizeKeys } from 'humps'
import nprogress from 'nprogress'
import * as schemas from './schemas'
import { KEYWORDS_PAGE_SIZE } from '../../constants'
import * as requestHeaders from '../../constants/request-headers'
import * as types from './index'
import { getKeyword, isKeywordUpdating } from './selectors'

const hasNextPage = action => action && action.meta && action.meta.nextPage

export const resetKeywords = () => ({
  type: types.RESET,
})

// async actions

let lastTaskId = null

export const fetchKeywords = (taskId, page = 1, pageSize = KEYWORDS_PAGE_SIZE) => {
  const offset = (page - 1) * pageSize
  const meta = {
    currentPage: page,
    pageSize,
    taskId,
  }

  if (page === 1) {
    lastTaskId = taskId
    nprogress.start()
  }

  return dispatch =>
    dispatch({
      [RSAA]: {
        endpoint: `/api/tasks/${taskId}/groups?limit=${pageSize}&offset=${offset}`,
        method: 'GET',
        credentials: 'same-origin',
        options: {
          authRequired: true,
        },
        bailout: state =>
          !taskId || lastTaskId !== taskId || (state.keywords.isFetching && page === 1),
        types: [
          {
            type: types.FETCH_LIST_REQUEST,
            meta,
          },
          {
            type: types.FETCH_LIST_SUCCESS,
            meta: (action, state, res) =>
              getJSON(res.clone()).then(json => ({
                ...meta,
                total: json.meta.total,
                nextPage: json.meta.total > offset + pageSize ? page + 1 : null,
                offset,
                lastTaskId,
              })),
            payload: (action, state, res) =>
              getJSON(res.clone()).then(json => normalize(json.data, schemas.KEYWORD_ARRAY)),
          },
          {
            type: types.FETCH_LIST_FAILURE,
            meta,
          },
        ],
      },
    }).then(action => {
      if (hasNextPage(action)) {
        nprogress.set(offset / action.meta.total)
        return dispatch(fetchKeywords(taskId, action.meta.nextPage, pageSize))
      }
      nprogress.done()
      return action
    })
}

export const fetchGroupInfo = (taskId, keywordId) => {
  const meta = {
    keywordId,
  }

  return {
    [RSAA]: {
      endpoint: `/api/tasks/${taskId}/groups/${keywordId}`,
      method: 'GET',
      credentials: 'same-origin',
      options: {
        authRequired: true,
      },
      bailout: state => getKeyword(state, keywordId).isFetching,
      types: [
        {
          type: types.FETCH_GROUP_INFO_REQUEST,
          meta,
        },
        {
          type: types.FETCH_GROUP_INFO_SUCCESS,
          meta,
          payload: (action, state, res) =>
            getJSON(res).then(json => normalize(json.data, schemas.KEYWORD)),
        },
        {
          type: types.FETCH_GROUP_INFO_FAILURE,
          meta,
        },
      ],
    },
  }
}

export const updateGroup = (taskId, keywordId, data) => ({
  [RSAA]: {
    endpoint: `/api/tasks/${taskId}/groups/${keywordId}`,
    method: 'PATCH',
    credentials: 'same-origin',
    headers: requestHeaders.POST,
    options: {
      authRequired: true,
    },
    bailout: state => isKeywordUpdating(state, keywordId),
    body: JSON.stringify({
      data: decamelizeKeys(data),
    }),
    types: [
      {
        type: types.UPDATE_GROUP_REQUEST,
        meta: { keywordId },
      },
      {
        type: types.UPDATE_GROUP_SUCCESS,
        payload: (action, state, res) =>
          getJSON(res).then(json => normalize(json.data, schemas.KEYWORD)),
        meta: { keywordId },
      },
      {
        type: types.UPDATE_GROUP_FAILURE,
        meta: { keywordId },
      },
    ],
  },
})
