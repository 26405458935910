import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown } from 'semantic-ui-react'
import WithOpenModal from '../../../../../../components/WithOpenModal'
import { TAG_SETTINGS_FORM } from '../../../../../../../constants/modal-types'

const TagSetingsButton = ({ taskId, keywordId, isMenuItem }) => {
  const makeButtonProps = openModal => ({
    content: 'Настройки тегов',
    onClick: () =>
      openModal(TAG_SETTINGS_FORM, {
        taskId,
        keywordId,
      }),
  })

  if (isMenuItem) {
    return (
      <WithOpenModal>
        {openModal => <Dropdown.Item {...makeButtonProps(openModal)} />}
      </WithOpenModal>
    )
  }

  return <WithOpenModal>{openModal => <Button {...makeButtonProps(openModal)} />}</WithOpenModal>
}

TagSetingsButton.defaultProps = {
  isMenuItem: false,
}

TagSetingsButton.propTypes = {
  taskId: PropTypes.string.isRequired,
  keywordId: PropTypes.string.isRequired,
  isMenuItem: PropTypes.bool,
}

export default TagSetingsButton
