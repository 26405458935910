import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TagEditForm from './TagEditForm'
import { updateGroup } from '../../../../../stores/keywords'
import { fetchAds } from '../../../../../stores/ads'
import {
  getInitialValues,
  getAvailableTags,
  updateTags,
  makeHandleResponse,
} from '../../../../../utils/tagEditFormUtils'
import * as tagFormTypes from '../../../../../constants/tag-form-types'

const handleResponse = makeHandleResponse('group')

class TagEditGroup extends Component {
  constructor(props) {
    super(props)

    this.handleResponse = handleResponse
  }

  handleSubmit = tagData => {
    const { group, updateGroup, task, fetchAds, finalizeChanges } = this.props

    return updateTags(tagData, group.tags, data => updateGroup(task.id, group.id, data))
      .then(this.handleResponse)
      .then(() => finalizeChanges())
      .then(() => fetchAds(task.id, group.id))
  }

  render() {
    const { task, group, canSubmit, availableTags } = this.props

    return (
      <TagEditForm
        form={tagFormTypes.GROUP}
        onSubmit={this.handleSubmit}
        initialValues={getInitialValues(group)}
        availableTags={availableTags || getAvailableTags(task)}
        isUpdating={group.isUpdating || false}
        canSubmit={canSubmit}
      />
    )
  }
}

TagEditGroup.defaultProps = {
  availableTags: null,
}

TagEditGroup.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    tags: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tags: PropTypes.objectOf(PropTypes.any),
    isUpdating: PropTypes.bool,
  }).isRequired,
  updateGroup: PropTypes.func.isRequired,
  fetchAds: PropTypes.func.isRequired,
  finalizeChanges: PropTypes.func.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  availableTags: PropTypes.arrayOf(PropTypes.string),
}

export default connect(
  null,
  { updateGroup, fetchAds },
)(TagEditGroup)
