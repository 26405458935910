import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'
import CreateTaskButton from './components/CreateTaskButton'
import TasksList from './components/TasksList'
import RequestError from '../components/RequestError'
import { FETCH_LIST_FAILURE, CREATE_FAILURE } from '../../stores/tasks'

const TasksListPage = ({
  match: {
    params: { projectId },
  },
}) => (
  <RequestError types={[FETCH_LIST_FAILURE, CREATE_FAILURE]}>
    <Grid>
      <Grid.Row centered>
        <Grid.Column width={5} />
        <Grid.Column width={3}>
          <CreateTaskButton />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column width={8}>
          <TasksList projectId={projectId} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </RequestError>
)

TasksListPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default TasksListPage
