import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { apiMiddleware } from 'redux-api-middleware'
import { authErrorMiddleware, authMiddleware } from 'auth-component/lib/redux'
import rootReducer from './rootReducer'

const configureStore = preloadedState =>
  createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunk, authErrorMiddleware, authMiddleware, apiMiddleware),
  )

export default configureStore
