import React from 'react'
import { connect } from 'react-redux'
import { fetchCities, getCities, isCitiesFetching } from '../../../../../../../stores/dictionaries'
import TagsListField from './TagsListField'

const mapStateToProps = state => ({
  items: getCities(state),
  isFetching: isCitiesFetching(state),
})

const CityField = props => (
  <TagsListField placeholder="Выберите города для замены тега..." {...props} />
)

export default connect(
  mapStateToProps,
  { fetchList: fetchCities },
)(CityField)
