const getNotificationError = status => {
  switch (status) {
    case 400:
      return 'Недопустимый запрос.'
    case 401:
      return 'Запрос отклонён. Требуется авторизация'
    case 403:
      return 'Запрос отклонён. К сожалению, у Вас недостаточно прав для выполнения этой операции.'
    case 404:
      return 'К сожалению, мы не смогли ничего найти.'
    case 422:
      return 'Данные в запросе недействительны.'
    case 500:
    case 502:
    case 503:
    case 504:
      return (
        'Сервер временно недоступен. Пожалуйста, попробуйте повторить запрос через некоторое время.' +
        ' Если ошибка повторится, обратитесь к администратору сервиса.'
      )
    // no default
  }

  return (
    'При выполнении запроса возникла непредвиденная ошибка.' +
    ' Если она повторится, обратитесь, пожалуйста, к администратору сервиса.'
  )
}

export default getNotificationError
