import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, Menu, Tab } from 'semantic-ui-react'
import TagEditTask from './TagEditTask'
import TagEditGroup from './TagEditGroup'
import { BLUE } from '../../../../../styles/variables'

const StyledModalContent = styled(Modal.Content)`
  .tab-title {
    position: relative;
    padding-right: 3px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      right: -10px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: ${BLUE};
      opacity: 0;
    }
  }

  .tab-title--changes::after {
    opacity: 1;
  }
`

const getTabTitleClassName = canSubmit => `tab-title${canSubmit ? ' tab-title--changes' : ''}`

const getPanes = ({
  task,
  group,
  onModalClose,
  taskHasChanges,
  groupkHasChanges,
  availableTags,
}) => {
  const panes = []

  if (task && task.tags) {
    panes.push({
      menuItem: (
        <Menu.Item key="task">
          <span className={getTabTitleClassName(taskHasChanges)}>Задача</span>
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <TagEditTask
            task={task}
            group={group}
            finalizeChanges={onModalClose}
            canSubmit={taskHasChanges}
            availableTags={availableTags}
          />
        </Tab.Pane>
      ),
    })
  }

  if (group && group.tags) {
    panes.push({
      menuItem: (
        <Menu.Item key="group">
          <span className={getTabTitleClassName(groupkHasChanges)}>Группа</span>
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <TagEditGroup
            task={task}
            group={group}
            finalizeChanges={onModalClose}
            canSubmit={groupkHasChanges}
            availableTags={availableTags}
          />
        </Tab.Pane>
      ),
    })
  }

  return panes
}

const TagSettings = props => (
  <Modal open closeOnDimmerClick={false} onClose={() => props.onModalClose(false)} closeIcon>
    <Modal.Header>Настройки тегов</Modal.Header>
    <StyledModalContent>
      <Tab panes={getPanes(props)} />
    </StyledModalContent>
  </Modal>
)

TagSettings.propTypes = {
  onModalClose: PropTypes.func.isRequired,
}

export default TagSettings
