import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchTasks, getTasks, isFetching, getOffset, getTotal } from '../../../../stores/tasks'
import TasksList from './components/TasksList'
import { TASK_PAGE_SIZE } from '../../../../constants'

const mapStateToProps = state => ({
  tasks: getTasks(state),
  isFetching: isFetching(state),
  offset: getOffset(state),
  total: getTotal(state),
})

class TasksListContainer extends Component {
  componentDidMount() {
    this.props.fetchTasks(this.props.projectId)
  }

  handleLoadMoreClick = () => {
    this.props.fetchTasks(this.props.projectId, TASK_PAGE_SIZE, this.props.offset + TASK_PAGE_SIZE)
  }

  render() {
    const canLoadMore = this.props.tasks.length < this.props.total

    return (
      <TasksList
        {...this.props}
        canLoadMore={canLoadMore}
        onLoadMoreClick={this.handleLoadMoreClick}
      />
    )
  }
}

TasksListContainer.propTypes = {
  fetchTasks: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.any).isRequired,
  offset: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired,
}

export default connect(
  mapStateToProps,
  {
    fetchTasks,
  },
)(TasksListContainer)
