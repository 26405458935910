import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import {
  fetchExclusions,
  getExclusionsParams,
  FETCH_TITLE_EXCLUSIONS_FAILURE,
  FETCH_TEXT_EXCLUSIONS_FAILURE,
} from '../../stores/exclusions'
import * as exclusionsFilters from '../../constants/exclusions-filters'
import ExclusionsPage from './components/ExclusionsPage'
import RequestError from '../components/RequestError'

const mapStateToProps = (
  state,
  {
    match: {
      params: { taskId, filter },
    },
  },
) => {
  const { currentPage, sortColumn, sortDirection } = getExclusionsParams(state, filter)

  return {
    filter,
    taskId,
    currentPage,
    sortColumn,
    sortDirection,
  }
}

class ExclusionsPageContainer extends Component {
  componentDidMount() {
    const { filter, taskId, currentPage, sortColumn, sortDirection, fetchExclusions } = this.props

    fetchExclusions(filter, taskId, currentPage, sortColumn, sortDirection)
  }

  componentDidUpdate(prevProps) {
    const { filter, taskId, currentPage, sortColumn, sortDirection, fetchExclusions } = this.props

    if (this.props.filter !== prevProps.filter) {
      fetchExclusions(filter, taskId, currentPage, sortColumn, sortDirection)
    }
  }

  render() {
    return (
      <RequestError types={[FETCH_TITLE_EXCLUSIONS_FAILURE, FETCH_TEXT_EXCLUSIONS_FAILURE]}>
        <ExclusionsPage />
      </RequestError>
    )
  }
}

ExclusionsPageContainer.propTypes = {
  taskId: PropTypes.string.isRequired,
  filter: PropTypes.oneOf([exclusionsFilters.TITLES, exclusionsFilters.TEXTS]).isRequired,
  fetchExclusions: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  sortColumn: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
}

export const ConnectedExclusionsPageContainer = connect(
  mapStateToProps,
  { fetchExclusions },
)(ExclusionsPageContainer)

export default withRouter(ConnectedExclusionsPageContainer)
