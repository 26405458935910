import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, Header, Grid, Loader, Dimmer, Message } from 'semantic-ui-react'
import AdsContainer from './AdsContainer'
import KeywordsFilterMenu from './KeywordsFilterMenu'
import VisibleKeywordsList from './VisibleKeywordsList'

const TaskPageWrap = styled.div`
  .ui.pointing.secondary.menu {
    font-size: 14px;

    & .item {
      padding-left: 0.9em;
      padding-right: 0.9em;
    }

    & .ui.label {
      margin-left: 0.7em;
      font-size: 14px;
    }
  }

  & .keywords-filter-menu,
  & .ads-filter-menu {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  & .ads-filter-menu {
    margin-top: 5px !important;
  }
`

const NoKeywordsMessage = ({ taskId }) => (
  <Message info compact>
    <Message.Header>Ни одной ключевой фразы не найдено</Message.Header>
    <Message.Content>
      Вы можете <Link to={`/${taskId}/keywords/new`}>добавить</Link> новые ключевые фразы прямо
      сейчас.
    </Message.Content>
  </Message>
)

const TaskPage = ({ keywordsIsFetching, noKeywords, taskId, selectKeyword }) => {
  if (!keywordsIsFetching && noKeywords) {
    return (
      <TaskPageWrap>
        <Header as="h2">Ключевые фразы</Header>
        <NoKeywordsMessage taskId={taskId} />
      </TaskPageWrap>
    )
  }

  return (
    <TaskPageWrap>
      <Container fluid>
        {keywordsIsFetching && (
          <Dimmer active inverted>
            <Loader size="large" content="Загрузка ключевых фраз..." />
          </Dimmer>
        )}
        <Grid columns={2} stackable>
          <Grid.Column width={5}>
            <Header as="h2">Ключевые фразы</Header>
            <KeywordsFilterMenu />
            {!keywordsIsFetching && !noKeywords && (
              <VisibleKeywordsList selectKeyword={selectKeyword} />
            )}
          </Grid.Column>
          <Grid.Column width={11}>
            {!keywordsIsFetching && !noKeywords && <AdsContainer />}
          </Grid.Column>
        </Grid>
      </Container>
    </TaskPageWrap>
  )
}

NoKeywordsMessage.propTypes = {
  taskId: PropTypes.number.isRequired,
}

TaskPage.propTypes = {
  keywordsIsFetching: PropTypes.bool.isRequired,
  noKeywords: PropTypes.bool.isRequired,
  taskId: PropTypes.number.isRequired,
  selectKeyword: PropTypes.func.isRequired,
}

export default TaskPage
