import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TagEditForm from './TagEditForm'
import { updateTask } from '../../../../../stores/tasks'
import { fetchAds } from '../../../../../stores/ads'
import {
  getInitialValues,
  getAvailableTags,
  updateTags,
  makeHandleResponse,
} from '../../../../../utils/tagEditFormUtils'
import * as tagFormTypes from '../../../../../constants/tag-form-types'

const handleResponse = makeHandleResponse('task')

class TagEditTask extends Component {
  constructor(props) {
    super(props)

    this.handleResponse = handleResponse
  }

  handleSubmit = tagData => {
    const { task, group, updateTask, fetchAds, finalizeChanges } = this.props

    return updateTags(tagData, task.tags, data => updateTask(task.id, data))
      .then(this.handleResponse)
      .then(() => finalizeChanges())
      .then(() => fetchAds(task.id, group.id))
  }

  render() {
    const { task, canSubmit, availableTags } = this.props

    return (
      <TagEditForm
        form={tagFormTypes.TASK}
        onSubmit={this.handleSubmit}
        initialValues={getInitialValues(task)}
        availableTags={availableTags || getAvailableTags(task)}
        isUpdating={task.isUpdating || false}
        canSubmit={canSubmit}
      />
    )
  }
}

TagEditTask.defaultProps = {
  availableTags: null,
}

TagEditTask.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    tags: PropTypes.objectOf(PropTypes.any),
    isUpdating: PropTypes.bool,
  }).isRequired,
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  updateTask: PropTypes.func.isRequired,
  fetchAds: PropTypes.func.isRequired,
  finalizeChanges: PropTypes.func.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  availableTags: PropTypes.arrayOf(PropTypes.string),
}

export default connect(
  null,
  { updateTask, fetchAds },
)(TagEditTask)
