import {
  FETCH_LIST_FAILURE as FETCH_ADS_LIST_FAILURE,
  SELECT_AD_FAILURE,
  DESELECT_AD_FAILURE,
  REJECT_TITLE_FAILURE,
  REJECT_TEXT_FAILURE,
} from '../../ads'
import {
  FETCH_TITLE_EXCLUSIONS_FAILURE,
  FETCH_TEXT_EXCLUSIONS_FAILURE,
  UNREJECT_TITLE_FAILURE,
  UNREJECT_TEXT_FAILURE,
} from '../../exclusions'
import {
  FETCH_LIST_FAILURE as FETCH_KEYWORDS_LIST_FAILURE,
  FETCH_GROUP_INFO_FAILURE,
} from '../../keywords'
import { ADD_FAILURE as ADD_NEW_KEYWORDS_FAILURE } from '../../newKeywords'
import {
  FETCH_LIST_FAILURE as FETCH_TASKS_LIST_FAILURE,
  FETCH_INFO_FAILURE as FETCH_TASK_INFO_FAILURE,
  CREATE_FAILURE as CREATE_TASK_FAILURE,
  DELETE_FAILURE as DELETE_TASK_FAILURE,
} from '../../tasks'

const getNotificationHeader = actionType => {
  switch (actionType) {
    case FETCH_KEYWORDS_LIST_FAILURE:
      return 'Загрузка ключевых фраз'
    case FETCH_GROUP_INFO_FAILURE:
      return 'Загрузка данных о группе'
    case FETCH_ADS_LIST_FAILURE:
      return 'Загрузка объявлений'
    case SELECT_AD_FAILURE:
      return 'Выбор объявления'
    case DESELECT_AD_FAILURE:
      return 'Отмена выбора объявления'
    case REJECT_TITLE_FAILURE:
      return 'Исключение заголовка'
    case REJECT_TEXT_FAILURE:
      return 'Исключение текста'
    case FETCH_TASKS_LIST_FAILURE:
      return 'Загрузка задач'
    case CREATE_TASK_FAILURE:
      return 'Добавление задачи'
    case DELETE_TASK_FAILURE:
      return 'Удаление задачи'
    case FETCH_TASK_INFO_FAILURE:
      return 'Загрузка данных о задаче'
    case ADD_NEW_KEYWORDS_FAILURE:
      return 'Добавление ключевых фраз'
    case FETCH_TITLE_EXCLUSIONS_FAILURE:
      return 'Загрузка исключённых заголовков'
    case FETCH_TEXT_EXCLUSIONS_FAILURE:
      return 'Загрузка исключённых текстов'
    case UNREJECT_TITLE_FAILURE:
      return 'Удаление заголовка из списка'
    case UNREJECT_TEXT_FAILURE:
      return 'Удаление текста из списка'
    // no default
  }

  return 'Неизвестное действие'
}

export default getNotificationHeader
