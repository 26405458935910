import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import qs from 'query-string'
import styled from 'styled-components'
import { Message, Dimmer, Icon } from 'semantic-ui-react'
import { makeTrans } from 'pannier'
import AvailableAds from './AvailableAds'
import SelectedAds from './SelectedAds'

const trans = makeTrans({
  selected_ads_plural_0: ({ count }) => `выбрано ${count} объявление.`,
  selected_ads_plural_1: ({ count }) => `выбрано ${count} объявления.`,
  selected_ads_plural_2: ({ count }) => `выбрано ${count} объявлений.`,
})

const StylishIcon = styled(Icon)`
  color: white;

  :hover {
    color: rgba(255, 255, 255, 0.7);
  }
`

const AdsPanel = ({
  availableAds,
  selectedAds,
  maxAds,
  nextKeyword,
  hideAd,
  rejectTitle,
  rejectText,
  selectAd,
  deselectAd,
}) => {
  if (availableAds.length === 0 && selectedAds.length === 0) {
    return <Message info content="Не найдено ни одного объявления." />
  }

  return (
    <Fragment>
      <Dimmer.Dimmable>
        <AvailableAds
          ads={availableAds}
          rejectTitle={rejectTitle}
          rejectText={rejectText}
          selectAd={selectAd}
          hideAd={hideAd}
        />
        <Dimmer active={maxAds && selectedAds.length >= maxAds}>
          <p>Поздравляем, {trans('selected_ads', { count: selectedAds.length })}</p>
          {nextKeyword && (
            <p>
              Вы можете перейти к выбору объявлений для следующей фразы: {nextKeyword.keyword}
              {` `}
              <Link to={`${window.location.pathname}?${qs.stringify({ id: nextKeyword.id })}`}>
                <StylishIcon size="big" name="arrow alternate circle right outline" />
              </Link>
            </p>
          )}
        </Dimmer>
      </Dimmer.Dimmable>
      {selectedAds.length > 0 && <SelectedAds ads={selectedAds} deselectAd={deselectAd} />}
    </Fragment>
  )
}

AdsPanel.defaultProps = {
  maxAds: null,
  nextKeyword: null,
  hideAd: null,
}

AdsPanel.propTypes = {
  availableAds: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedAds: PropTypes.arrayOf(PropTypes.object).isRequired,
  maxAds: PropTypes.number,
  nextKeyword: PropTypes.shape({
    id: PropTypes.string.isRequired,
    keyword: PropTypes.string.isRequired,
  }),
  hideAd: PropTypes.func,
  rejectTitle: PropTypes.func.isRequired,
  rejectText: PropTypes.func.isRequired,
  selectAd: PropTypes.func.isRequired,
  deselectAd: PropTypes.func.isRequired,
}

export default AdsPanel
