import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import RequestError from '../components/RequestError'
import TaskPage from './components/TaskPage'
import {
  resetKeywords,
  fetchKeywords,
  getKeywords,
  FETCH_LIST_FAILURE as FETCH_KEYWORDS_LIST_FAILURE,
} from '../../stores/keywords'
import { FETCH_INFO_FAILURE as FETCH_TASK_INFO_FAILURE } from '../../stores/tasks'
import { FETCH_LIST_FAILURE as FETCH_ADS_LIST_FAILURE } from '../../stores/ads'

function mapStateToProps(state, { history, match, location }) {
  const { params } = match

  return {
    taskId: Number(params.taskId),
    location: location.pathname,
    history,
    keywordsIsFetching: state.keywords.isFetching,
    noKeywords: getKeywords(state).length === 0,
  }
}

class TaskPageContainer extends Component {
  componentDidMount() {
    this.props.fetchKeywords(this.props.taskId)
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.noKeywords && !this.props.keywordsIsFetching && nextProps.keywordsIsFetching) {
      return false
    }

    return true
  }

  componentWillUnmount() {
    this.props.resetKeywords()
  }

  selectKeyword = keywordId => {
    const { history, location } = this.props

    history.replace(`${location}?id=${keywordId}`)
  }

  render() {
    return (
      <RequestError
        types={[FETCH_KEYWORDS_LIST_FAILURE, FETCH_TASK_INFO_FAILURE, FETCH_ADS_LIST_FAILURE]}
      >
        <TaskPage
          keywordsIsFetching={this.props.keywordsIsFetching}
          noKeywords={this.props.noKeywords}
          taskId={this.props.taskId}
          selectKeyword={this.selectKeyword}
        />
      </RequestError>
    )
  }
}

TaskPageContainer.propTypes = {
  taskId: PropTypes.number.isRequired,
  keywordsIsFetching: PropTypes.bool.isRequired,
  noKeywords: PropTypes.bool.isRequired,
  resetKeywords: PropTypes.func.isRequired,
  fetchKeywords: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
}

export default connect(
  mapStateToProps,
  { resetKeywords, fetchKeywords },
)(TaskPageContainer)
