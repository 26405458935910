function addLeadingZero(num) {
  return num > 9 ? num.toString() : `0${num}`
}

export default isoDate => {
  const date = new Date(isoDate)
  const day = addLeadingZero(date.getDate())
  const month = addLeadingZero(date.getMonth() + 1)

  return `${day}.${month}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
}
