import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  unrejectItem,
  fetchExclusions,
  getExclusions,
  getExclusionsParams,
} from '../../../../stores/exclusions'
import ExcludedTexts from './components/ExcludedTexts'

const mapStateToProps = (
  state,
  {
    match: {
      params: { filter },
    },
  },
) => {
  const { isFetching, currentPage, totalItems, sortColumn, sortDirection } = getExclusionsParams(
    state,
    filter,
  )

  return {
    filter,
    exclusions: getExclusions(state, filter),
    isFetching,
    currentPage,
    totalItems,
    sortColumn,
    sortDirection,
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    currentPage,
    match: {
      params: { taskId, filter },
    },
  },
) => ({
  onItemClick: itemId => dispatch(unrejectItem(filter, taskId, itemId)),
  sortExclusions: (sortColumn, sortDirection) =>
    dispatch(fetchExclusions(filter, taskId, currentPage, sortColumn, sortDirection)),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ExcludedTexts),
)
