import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as menuItems from '../../../../../../../constants/ads-menu'
import {
  selectAdsMenu,
  getAds,
  getTitles,
  getTexts,
  getIntersectionsAds,
  getSelectedAds,
} from '../../../../../../../stores/ads'
import CountableItem from '../../../../../../components/CountableItem'

function getCount(state, itemName) {
  switch (itemName) {
    case menuItems.ADS:
      return getAds(state).length
    case menuItems.TITLES:
      return getTitles(state).length
    case menuItems.TEXTS:
      return getTexts(state).length
    case menuItems.INTERSECTIONS:
      return getIntersectionsAds(state).length + getSelectedAds(state).length
    default:
      return 0
  }
}

function mapStateToProps(state, { name }) {
  return {
    active: name === state.ads.activeMenuItem,
    count: getCount(state, name),
  }
}

function mapDispatchToProps(dispatch, { name }) {
  return {
    onClick: () => dispatch(selectAdsMenu(name)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CountableItem),
)
