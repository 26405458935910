import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Menu, Icon } from 'semantic-ui-react'
import {
  FILTER_ALL,
  FILTER_EMPTY,
  FILTER_READY,
  FILTER_ACCESSIBLE,
} from '../../../../constants/keywords-filter'
import KeywordsFilterItem from './components/KeywordsFilterItem'
import Tooltip from '../../../components/Tooltip'

const KeywordsFilterMenu = ({ className }) => (
  <Menu pointing secondary className={className}>
    <Tooltip id="keywords-menu-tooltip" />
    <KeywordsFilterItem filter={FILTER_ACCESSIBLE}>
      <Icon
        name="chevron circle right"
        size="large"
        className="item-icon"
        data-for="keywords-menu-tooltip"
        data-tip="К отбору"
      />
      <span className="item-text">К отбору</span>
    </KeywordsFilterItem>
    <KeywordsFilterItem filter={FILTER_READY}>
      <Icon
        name="check circle"
        size="large"
        className="item-icon"
        data-for="keywords-menu-tooltip"
        data-tip="Готовые"
      />
      <span className="item-text">Готовые</span>
    </KeywordsFilterItem>
    <KeywordsFilterItem filter={FILTER_EMPTY}>
      <Icon
        name="circle outline"
        size="large"
        className="item-icon"
        data-for="keywords-menu-tooltip"
        data-tip="Пустые"
      />
      <span className="item-text">Пустые</span>
    </KeywordsFilterItem>
    <KeywordsFilterItem filter={FILTER_ALL}>
      <Icon
        name="boxes"
        size="large"
        className="item-icon"
        data-for="keywords-menu-tooltip"
        data-tip="Все"
      />
      <span className="item-text">Все</span>
    </KeywordsFilterItem>
  </Menu>
)

KeywordsFilterMenu.propTypes = { className: PropTypes.string.isRequired }

const StyledKeywordsFilterMenu = styled(KeywordsFilterMenu)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 48.38px !important;

  .item-icon {
    display: none;
    margin-right: 0 !important;
  }

  @media (max-width: 1620px) {
    .item {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }

    .item-icon {
      display: block;
    }

    .item-text {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    .item {
      padding-left: 3px !important;
      padding-right: 3px !important;
      margin-bottom: -9px !important;
      transform: scale(0.7);

      &:not(:last-child) {
        margin-right: -13px !important;
      }
    }
  }
`

export default StyledKeywordsFilterMenu
