import compact from 'lodash/compact'
import isEqual from 'lodash/isEqual'
import transform from 'lodash/transform'
import uniq from 'lodash/uniq'
import { SubmissionError } from 'redux-form'
import logFormErrors from './logFormErrors'
import { CITY, PRICE, SHOP, METRO, DIS, DISCOUNT } from '../constants/tag-names'
import * as taskSubjects from '../constants/task-subjects'
import getMessage from '../messages'

const isError = action => action && action.error

const getServerErrors = payload => {
  if (payload && payload.response && payload.status === 422) {
    return payload.response.errors || []
  }
  return []
}

const isNotEmptyArray = val => val && val.filter(v => !!v).length

export const getInitialValues = entity => {
  if (!entity || !entity.tags) {
    return {}
  }

  const { city, price, shop, metro, dis, discount } = entity.tags

  return {
    [CITY]: (city && city.values) || [],
    [PRICE]: price && price.value,
    [SHOP]: (shop && shop.values) || [],
    [METRO]: (metro && metro.values) || [],
    [DIS]: dis || { value: null, min: null, max: null },
    [DISCOUNT]: discount || {
      value: null,
      min: null,
      max: null,
      currency: 'RUR',
    },
  }
}

const getCommonTags = () => [CITY, PRICE, METRO, DIS, DISCOUNT]

export const getAvailableTags = task => {
  if (!task || !task.subject) {
    return null
  }

  switch (task.subject) {
    case taskSubjects.MATTRESS:
      return [...getCommonTags(), SHOP]
    case taskSubjects.LIGHT:
      return [...getCommonTags(), SHOP]
    case taskSubjects.MEDIC:
      return getCommonTags()
    // no default
  }

  throw new Error(`Unknown task subject: ${task.subject}`)
}

const disValsToNumbers = tag => ({
  value: +tag.value || null,
  min: +tag.min || null,
  max: +tag.max || null,
})

const getDisErrors = dis => {
  const { value, min, max } = disValsToNumbers(dis)
  const errors = { value: [], min: [], max: [] }

  if (value && max && value > max) {
    errors.value.push(
      getMessage('form.error.greater_than', {
        value,
        comparedTo: max,
      }),
    )
  }

  if (min && max && min > max) {
    errors.min.push(
      getMessage('form.error.greater_than', {
        value: min,
        comparedTo: max,
      }),
    )
  }

  if (value && min && value < min) {
    errors.value.push(
      getMessage('form.error.less_than', {
        value,
        comparedTo: min,
      }),
    )
  }

  if (max && min && max < min) {
    errors.max.push(
      getMessage('form.error.less_than', {
        value: max,
        comparedTo: min,
      }),
    )
  }

  return errors
}

export const validate = ({ dis, discount }) => {
  const errors = {}

  if (dis) {
    const disErrors = getDisErrors(dis)

    if (dis.max && +dis.max > 100) {
      disErrors.max.push(
        getMessage('form.error.greater_than', {
          value: dis.max,
          comparedTo: 100,
        }),
      )
    }

    if (dis.min && +dis.min < 1) {
      disErrors.min.push(
        getMessage('form.error.less_than', {
          value: dis.min,
          comparedTo: 1,
        }),
      )
    }

    Object.entries(dis).forEach(entry => {
      if (parseInt(+entry[1], 10) !== +entry[1]) {
        disErrors[entry[0]].push(getMessage('form.error.not_integer'))
      }
    })

    if (disErrors.value.length || disErrors.min.length || disErrors.max.length) {
      errors.dis = disErrors
    }
  }

  if (discount) {
    const discountErrors = getDisErrors(discount)

    if (discountErrors.value.length || discountErrors.min.length || discountErrors.max.length) {
      errors.discount = discountErrors
    }
  }

  return errors
}

const getDisEntity = (tag, extraFields = {}) => {
  if (!tag) {
    return null
  }

  const values = disValsToNumbers(tag)
  if (Object.values(values).every(val => !val)) {
    return null
  }

  return {
    ...tag,
    ...values,
    ...extraFields,
  }
}

export const toEntity = formData => {
  if (!formData) {
    return null
  }

  return {
    city: isNotEmptyArray(formData.city) ? { values: compact(formData.city) } : null,
    price: formData.price ? { value: Number(formData.price), currency: 'RUR' } : null,
    shop: isNotEmptyArray(formData.shop) ? { values: uniq(compact(formData.shop)) } : null,
    metro: isNotEmptyArray(formData.metro)
      ? { values: compact(formData.metro), city: 'msk' }
      : null,
    dis: getDisEntity(formData.dis),
    discount: getDisEntity(formData.discount, { currency: 'RUR' }),
  }
}

export const updateTags = (formTags, originalTags, update) => {
  if (!formTags || !originalTags) {
    return Promise.resolve()
  }

  const oldTags = JSON.parse(JSON.stringify(originalTags))
  const tags = toEntity(formTags)

  if (isEqual(tags, oldTags)) {
    return Promise.resolve()
  }

  const newTags = transform(
    oldTags,
    (acc, value, key, obj) => {
      acc[key] = isEqual(obj[key], tags[key]) ? value : tags[key] || null
    },
    {},
  )

  return update({ tags: newTags })
}

export const makeHandleResponse = formName => action => {
  if (!isError(action)) {
    return action
  }

  const serverError = getServerErrors(action.payload)

  logFormErrors(formName, serverError)

  throw new SubmissionError({ _error: getMessage('form.submit.unk_error') })
}
