import React from 'react'
import { Field } from 'redux-form'
import styled from 'styled-components'
import PriceField from './PriceField'
import { isNumber } from '../../../../../../../utils/validators'

const DisFieldWrap = styled.div`
  display: flex;

  & > * {
    margin-right: 20px;
  }
`

const DisField = () => (
  <DisFieldWrap>
    <Field
      name="value"
      label="Значение"
      component={PriceField}
      validate={isNumber}
      placeholder=""
    />
    <Field
      name="min"
      label="Минимальное значение"
      component={PriceField}
      validate={isNumber}
      placeholder=""
    />
    <Field
      name="max"
      label="Максимальное значение"
      component={PriceField}
      validate={isNumber}
      placeholder=""
    />
  </DisFieldWrap>
)

export default DisField
