import { connect } from 'react-redux'
import { showModal } from '../../../../stores/modal'
import { CREATE_TASK_FORM } from '../../../../constants/modal-types'
import CreateTaskButton from './components/CreateTaskButton'

function mapDispatchToProps(dispatch) {
  return {
    openForm: () => dispatch(showModal(CREATE_TASK_FORM)),
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(CreateTaskButton)
