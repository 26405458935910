import { RSAA, getJSON } from 'redux-api-middleware'
import { camelizeKeys } from 'humps'
import * as types from './constants'
import { isCitiesFetching, isMetroFetching } from './selectors'
import * as requestHeaders from '../../constants/request-headers'

export const fetchCities = () => ({
  [RSAA]: {
    endpoint: '/api/cities',
    method: 'GET',
    credentials: 'same-origin',
    headers: requestHeaders.GET,
    options: {
      authRequired: true,
    },
    bailout: state => isCitiesFetching(state),
    types: [
      types.FETCH_CITIES_LIST_REQUEST,
      {
        type: types.FETCH_CITIES_LIST_SUCCESS,
        payload: (action, state, res) => getJSON(res).then(json => camelizeKeys(json.data)),
      },
      types.FETCH_CITIES_LIST_FAILURE,
    ],
  },
})

export const fetchMetroList = () => ({
  [RSAA]: {
    endpoint: '/api/metro/msk',
    method: 'GET',
    credentials: 'same-origin',
    headers: requestHeaders.GET,
    options: {
      authRequired: true,
    },
    bailout: state => isMetroFetching(state),
    types: [
      types.FETCH_METRO_LIST_REQUEST,
      {
        type: types.FETCH_METRO_LIST_SUCCESS,
        payload: (action, state, res) => getJSON(res).then(json => camelizeKeys(json.data)),
      },
      types.FETCH_METRO_LIST_FAILURE,
    ],
  },
})
