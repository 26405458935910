const extractPrice = value => {
  const nums = value.replace(/\s+/g, '').match(/\d+([.,]\d+)?/g)

  if (!nums) {
    return null
  }

  const before = /[^\d.,]+.*\d+([.,]\d+)?/.test(value)
  const after = /^\d+([.,]\d+)?.*[^\d.,]+/.test(value)
  const dots = /(,|^\.|\.$)/.test(value)
  const parsed = parseFloat(nums[0]).toString()

  if (nums && nums.length === 1 && (before || after || dots || parsed !== nums[0])) {
    return parseFloat(nums[0].replace(',', '.')).toString()
  }

  return null
}

export default extractPrice
