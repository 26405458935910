import { combineReducers } from 'redux'

export * from './actions'

const prefix = '@newKeywords/'

export const ADD_REQUEST = `${prefix}ADD_REQUEST`
export const ADD_SUCCESS = `${prefix}ADD_SUCCESS`
export const ADD_FAILURE = `${prefix}ADD_FAILURE`

function isUploading(state = false, action) {
  switch (action.type) {
    case ADD_REQUEST:
      return true
    case ADD_SUCCESS:
      return false
    case ADD_FAILURE:
      return false
    default:
      return state
  }
}

export default combineReducers({
  isUploading,
})
