import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Message, Table, Popup } from 'semantic-ui-react'
import RelevanceScore from './RelevanceScore'
import AdText from './AdText'
import * as selectedStatuses from '../../../../../constants/selected-statuses'

const TextsWrap = styled.div`
  user-select: none;

  .selectable-row {
    cursor: pointer;
  }

  .non-selectable-row.non-selectable-row.non-selectable-row {
    background: #fffaf3;
    color: #573a08;

    &:hover {
      background: #fffaf3 !important;
      color: #573a08 !important;
    }
  }
`

const AvailableTextsWrap = styled.div`
  margin-top: 16px;
  margin-bottom: 10px;
  height: calc(70vh - 200px);
  width: calc(100% + 10px);
  overflow-x: hidden;
  overflow-y: scroll;
`

const SelectedTextsWrap = styled.div`
  height: calc(30vh - 30px);
  width: calc(100% + 10px);
  overflow-x: hidden;
  overflow-y: scroll;
`

const TableRow = ({ onClick, item, reject }) => {
  const { id, relevanceLevel, renderText, isRejecting, status } = item
  const appearance = { className: 'selectable-row' }
  const isStatusFrozen = status === selectedStatuses.FROZEN

  if (status === selectedStatuses.SELECTED) {
    appearance.positive = true
  } else if (isStatusFrozen) {
    appearance.className = 'non-selectable-row'
  }

  return (
    <Table.Row onClick={onClick} {...appearance}>
      <Table.Cell collapsing>
        <RelevanceScore relevanceLevel={relevanceLevel} />
      </Table.Cell>
      <Table.Cell>
        <AdText chunks={renderText} />
      </Table.Cell>
      <Table.Cell collapsing>
        {isStatusFrozen ? (
          <Popup
            trigger={<Button circular icon="lock" color="yellow" />}
            content="Данный элемент исключить нельзя, т.к. он используется в отобранных объявлениях."
            position="top right"
          />
        ) : (
          <Button
            circular
            icon="ban"
            color="red"
            loading={isRejecting}
            onClick={e => {
              reject(id)
              e.stopPropagation()
            }}
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

const TextsList = ({ availableItems, selectedItems, onRejectClick, selectItem, deselectItem }) => {
  if (!availableItems.length && !selectedItems.length) {
    return <Message info content="Не найдено ни одного элемента." />
  }

  return (
    <TextsWrap>
      <AvailableTextsWrap>
        <Table selectable>
          <Table.Body>
            {availableItems.map(item => (
              <TableRow
                key={item.id}
                item={item}
                reject={onRejectClick}
                onClick={() => selectItem(item.id)}
              />
            ))}
          </Table.Body>
        </Table>
      </AvailableTextsWrap>
      <SelectedTextsWrap>
        <Table selectable>
          <Table.Body>
            {selectedItems.map(item => (
              <TableRow
                key={item.id}
                item={item}
                reject={onRejectClick}
                onClick={() => {
                  if (item.status !== selectedStatuses.FROZEN) {
                    return deselectItem(item.id)
                  }
                  return null
                }}
              />
            ))}
          </Table.Body>
        </Table>
      </SelectedTextsWrap>
    </TextsWrap>
  )
}

const itemProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  renderText: PropTypes.any.isRequired,
  relevanceLevel: PropTypes.string.isRequired,
  isRejecting: PropTypes.bool,
  selected: PropTypes.bool,
})

TableRow.propTypes = {
  item: itemProps.isRequired,
  onClick: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
}

TextsList.propTypes = {
  availableItems: PropTypes.arrayOf(itemProps).isRequired,
  selectedItems: PropTypes.arrayOf(itemProps).isRequired,
  onRejectClick: PropTypes.func.isRequired,
  selectItem: PropTypes.func.isRequired,
  deselectItem: PropTypes.func.isRequired,
}

export default TextsList
