import React from 'react'
import styled from 'styled-components'
import { Container, Grid } from 'semantic-ui-react'
import { ProjectsList } from 'projects-embed'

const IndexPageWrap = styled.div`
  padding-top: 50px;
`

const IndexPage = () => (
  <IndexPageWrap>
    <Container>
      <Grid columns={1} centered>
        <Grid.Row>
          <Grid.Column width={8}>
            <ProjectsList />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </IndexPageWrap>
)

export default IndexPage
