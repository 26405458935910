const relevanceLevelMap = {
  high: 3,
  normal: 2,
  low: 1,
  none: 0,
}

function qualityLevel(quality) {
  if (quality >= 1.5) {
    return 5
  }

  if (quality >= 1.15) {
    return 4
  }

  if (quality >= 0.85) {
    return 3
  }

  if (quality >= 0.5) {
    return 2
  }

  return 1
}

export const adRating = (quality, titleRelevanceLevel, textRelevanceLevel) => {
  const titleWeight = 2.5 * relevanceLevelMap[titleRelevanceLevel]
  const textWeight = 1.5 * relevanceLevelMap[textRelevanceLevel]
  const qualityWeight = 1.5 * qualityLevel(quality)

  const weight = (25 - titleWeight - textWeight - qualityWeight).toFixed(0)

  return 11 - (0.625 * weight - 2.5).toFixed(0)
}
