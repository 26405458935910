import { injectGlobal } from 'styled-components'
import 'semantic-ui-css/semantic.min.css'
import { TEXT_BLACK } from './variables'

/* eslint-disable */

injectGlobal`
  @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&subset=cyrillic');

  body {
    color: rgba(0, 0, 0, .87);
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(#f7f7f7, #fbfbfb);
  }

  button {
    font-family: inherit;
    border: unset;
    outline: unset;
    background: unset;
    cursor: pointer;
  }

  td {
    font-size: 16px;
    line-height: 22px;
  }

  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }

  .ui {
    font-family: 'Roboto', sans-serif !important;

    input,
    textarea,
    .header:not(.condensed),
    .heading,
    .title,
    .active {
      font-family: 'Roboto', sans-serif !important;
    }

    &.modal {
      position: relative;
    }

    &.modal > .close.close {
      top: 1.05rem;
      right: 1rem;
      color: ${TEXT_BLACK};
    }
  }

  .no-decoration {
    text-decoration: none;
    color: ${TEXT_BLACK} !important;

    &:hover {
      color: ${TEXT_BLACK} !important;
      text-decoration: underline;
    }
  }

  .condensed {
    font-family: 'Roboto Condensed', sans-serif !important;
  }

  .task-list.task-list {
    font-size: 16px;
  }

  .no-opacity {
    opacity: 1 !important;
  }
`
