import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'semantic-ui-react'

const CreateTaskButton = ({ openForm }) => (
  <Button positive onClick={openForm} floated="right">
    <Icon name="add" />
    Создать задачу
  </Button>
)

CreateTaskButton.propTypes = {
  openForm: PropTypes.func.isRequired,
}

export default CreateTaskButton
