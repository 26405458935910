import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Breadcrumb, Icon } from 'semantic-ui-react'
import * as routes from '../../../../../../constants/routes'
import * as pageNames from '../../../../../../constants/page-names'

const defaultProjectName = 'Проект'
const defaultTaskName = 'Задача'

const createPathNodes = ({ match, projectName, taskName }) => {
  const { projectId, taskId, filter } = match.params

  switch (match.path) {
    case routes.TASKS_LIST_PAGE:
      return [
        { link: '/', content: pageNames.MAIN },
        { link: `/${projectId}`, content: projectName || defaultProjectName },
      ]
    case routes.TASK_PAGE:
      return [
        { link: '/', content: pageNames.MAIN },
        { link: `/${projectId}`, content: projectName || defaultProjectName },
        {
          link: `/${projectId}/${taskId}`,
          content: taskName || defaultTaskName,
        },
      ]
    case routes.EXCLUSIONS_PAGE:
      return [
        { link: '/', content: pageNames.MAIN },
        { link: `/${projectId}`, content: projectName || defaultProjectName },
        {
          link: `/${projectId}/${taskId}`,
          content: taskName || defaultTaskName,
        },
        {
          link: `/${projectId}/${taskId}/exclusions/${filter}`,
          content: pageNames.EXCLUSIONS,
        },
      ]
    case routes.NEWKEYWORDS_PAGE:
      return [
        { link: '/', content: pageNames.MAIN },
        { link: `/${projectId}`, content: projectName || defaultProjectName },
        {
          link: `/${projectId}/${taskId}`,
          content: taskName || defaultTaskName,
        },
        {
          link: `/${projectId}/${taskId}/keywords/new`,
          content: pageNames.NEWKEYWORDS,
        },
      ]
    default:
      return [{ link: '/', content: pageNames.MAIN }]
  }
}

/* eslint-disable react/no-array-index-key */
const Breadcrumbs = ({ className, ...props }) => (
  <Breadcrumb className={className}>
    {createPathNodes(props).map((item, idx, arr) => {
      if (idx === arr.length - 1) {
        return (
          <Breadcrumb.Section
            key={idx}
            className="breadcrumb-item--active"
            active
            content={item.content}
          />
        )
      }
      return (
        <Fragment key={idx}>
          <Breadcrumb.Section className="breadcrumb-item">
            <Link to={item.link} className="no-decoration">
              {item.content}
            </Link>
          </Breadcrumb.Section>
          <Breadcrumb.Divider>
            <Icon name="right chevron" />
          </Breadcrumb.Divider>
        </Fragment>
      )
    })}
  </Breadcrumb>
)
/* eslint-enable react/no-array-index-key */

const StyledBreadcrumbs = styled(Breadcrumbs)`
  display: flex !important;
  align-items: baseline;

  .breadcrumb-item a,
  .breadcrumb-item--active {
    display: block;
    max-width: 13vw;
    min-height: 16px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    margin-right: 5px;
  }
`

Breadcrumbs.propTypes = {
  className: PropTypes.string.isRequired,
}

export default StyledBreadcrumbs
