import React from 'react'
import { Menu } from 'semantic-ui-react'
import * as menuItems from '../../../../../../constants/ads-menu'
import AdsMenuItem from './components/AdsMenuItem'

const AdsMenu = () => (
  <Menu pointing secondary className="ads-filter-menu">
    <AdsMenuItem name={menuItems.ADS}>Объявления</AdsMenuItem>
    <AdsMenuItem name={menuItems.TITLES}>Заголовки</AdsMenuItem>
    <AdsMenuItem name={menuItems.TEXTS}>Тексты</AdsMenuItem>
    <AdsMenuItem name={menuItems.INTERSECTIONS}>Пересечения</AdsMenuItem>
  </Menu>
)

export default AdsMenu
