import {
  FETCH_LIST_FAILURE as FETCH_ADS_LIST_FAILURE,
  SELECT_AD_FAILURE,
  DESELECT_AD_FAILURE,
  REJECT_TITLE_FAILURE,
  REJECT_TEXT_FAILURE,
} from '../ads'
import {
  FETCH_TITLE_EXCLUSIONS_FAILURE,
  FETCH_TEXT_EXCLUSIONS_FAILURE,
  UNREJECT_TITLE_FAILURE,
  UNREJECT_TEXT_FAILURE,
} from '../exclusions'
import {
  FETCH_LIST_FAILURE as FETCH_KEYWORDS_LIST_FAILURE,
  FETCH_GROUP_INFO_FAILURE,
} from '../keywords'
import {
  FETCH_LIST_FAILURE as FETCH_TASK_LIST_FAILURE,
  FETCH_INFO_FAILURE as FETCH_TASK_INFO_FAILURE,
  CREATE_FAILURE as CREATE_TASK_FAILURE,
  DELETE_FAILURE as DELETE_TASK_FAILURE,
} from '../tasks'
import { ADD_FAILURE as ADD_NEW_KEYWORDS_FAILURE } from '../newKeywords'
import * as notificationTypes from '../../constants/notification-types'
import createNotification from './utils/createNotification'
import getNotificationHeader from './utils/getNotificationHeader'
import getNotificationError from './utils/getNotificationError'

export * from './actions'

const prefix = '@notifications/'

export const ADD = `${prefix}ADD`
export const REMOVE = `${prefix}REMOVE`

const IGNORE_STATUSES = [401, 403, 404, 422]

const setupErrorNotification = (state, actionType, action, ignoreStatuses) => {
  const { status } = action.payload

  if (ignoreStatuses && ignoreStatuses.includes(status)) {
    return state
  }

  return [
    ...state,
    createNotification({
      notificationType: notificationTypes.ERROR,
      header: getNotificationHeader(actionType),
      content: getNotificationError(status),
    }),
  ]
}

const notifications = (state = [], action) => {
  switch (action.type) {
    case ADD:
      return [...state, createNotification(action)]
    case REMOVE:
      return state.filter(notification => notification.id !== action.id)
    case FETCH_GROUP_INFO_FAILURE:
      return setupErrorNotification(state, FETCH_GROUP_INFO_FAILURE, action)
    case SELECT_AD_FAILURE:
      return setupErrorNotification(state, SELECT_AD_FAILURE, action)
    case DESELECT_AD_FAILURE:
      return setupErrorNotification(state, DESELECT_AD_FAILURE, action)
    case REJECT_TITLE_FAILURE:
      return setupErrorNotification(state, REJECT_TITLE_FAILURE, action)
    case REJECT_TEXT_FAILURE:
      return setupErrorNotification(state, REJECT_TEXT_FAILURE, action)
    case DELETE_TASK_FAILURE:
      return setupErrorNotification(state, DELETE_TASK_FAILURE, action)
    case UNREJECT_TITLE_FAILURE:
      return setupErrorNotification(state, UNREJECT_TITLE_FAILURE, action)
    case UNREJECT_TEXT_FAILURE:
      return setupErrorNotification(state, UNREJECT_TEXT_FAILURE, action)
    case FETCH_TASK_LIST_FAILURE:
      return setupErrorNotification(state, FETCH_TASK_LIST_FAILURE, action, IGNORE_STATUSES)
    case CREATE_TASK_FAILURE:
      return setupErrorNotification(state, CREATE_TASK_FAILURE, action, IGNORE_STATUSES)
    case FETCH_KEYWORDS_LIST_FAILURE:
      return setupErrorNotification(state, FETCH_KEYWORDS_LIST_FAILURE, action, IGNORE_STATUSES)
    case FETCH_TASK_INFO_FAILURE:
      return setupErrorNotification(state, FETCH_TASK_INFO_FAILURE, action, IGNORE_STATUSES)
    case FETCH_ADS_LIST_FAILURE:
      return setupErrorNotification(state, FETCH_ADS_LIST_FAILURE, action, IGNORE_STATUSES)
    case FETCH_TITLE_EXCLUSIONS_FAILURE:
      return setupErrorNotification(state, FETCH_TITLE_EXCLUSIONS_FAILURE, action, IGNORE_STATUSES)
    case FETCH_TEXT_EXCLUSIONS_FAILURE:
      return setupErrorNotification(state, FETCH_TEXT_EXCLUSIONS_FAILURE, action, IGNORE_STATUSES)
    case ADD_NEW_KEYWORDS_FAILURE:
      return setupErrorNotification(state, ADD_NEW_KEYWORDS_FAILURE, action, IGNORE_STATUSES)
    default:
      return state
  }
}

export default notifications
