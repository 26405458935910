import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'semantic-ui-react'
import Tooltip from '../../../../components/Tooltip'
import * as adsViews from '../../../../../constants/ads-views'

const AdsViewSwitchWrap = styled.div`
  margin-right: 0 !important;

  #ads-view-switch-tooltip {
    width: 240px;
    text-align: center;
  }
`

const makeOnClick = (view, setView) => () =>
  setView(view === adsViews.SIMPLE ? adsViews.EXTENDED : adsViews.SIMPLE)

const getIconName = view => (view === adsViews.SIMPLE ? 'list alternate outline' : 'list ul')

const AdsViewSwitch = ({ view, setView }) => (
  <AdsViewSwitchWrap>
    <Button
      icon={getIconName(view)}
      onClick={makeOnClick(view, setView)}
      data-for="ads-view-switch-tooltip"
      data-tip
    />
    <Tooltip
      id="ads-view-switch-tooltip"
      getContent={() =>
        `Перейти к ${view === adsViews.SIMPLE ? 'расширенному' : 'упрощенному'} виду`
      }
    />
  </AdsViewSwitchWrap>
)

AdsViewSwitch.propTypes = {
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
}

export default AdsViewSwitch
