import rollbar from '../rollbar'

export default (formName, serverErrors) => {
  if (serverErrors.length) {
    rollbar.error('Form submit failed', {
      formName,
      serverErrors,
    })
  }
}
