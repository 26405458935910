import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Popup } from 'semantic-ui-react'

const TaskControlsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 93px;
`

const TaskControls = ({ projectId, taskId, onDeleteClick, isDeleting }) => (
  <TaskControlsWrap>
    <Popup
      trigger={
        <Button
          positive
          size="small"
          icon="add circle"
          as={Link}
          to={`${projectId}/${taskId}/keywords/new`}
        />
      }
    >
      Добавить ключевые слова
    </Popup>
    <Popup
      trigger={
        <Button negative size="small" icon="delete" loading={isDeleting} onClick={onDeleteClick} />
      }
    >
      Удалить задачу
    </Popup>
  </TaskControlsWrap>
)

TaskControls.propTypes = {
  projectId: PropTypes.string.isRequired,
  taskId: PropTypes.number.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
}

TaskControls.defaultProps = {
  isDeleting: false,
}

export default TaskControls
