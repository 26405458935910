import React from 'react'
import { Container } from 'semantic-ui-react'
import RequestError from '../components/RequestError'
import KeywordsInputForm from './components/KeywordsInputForm'
import { ADD_FAILURE } from '../../stores/newKeywords'

export default () => (
  <RequestError types={[ADD_FAILURE]}>
    <Container>
      <KeywordsInputForm />
    </Container>
  </RequestError>
)
