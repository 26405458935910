import React from 'react'
import { connect } from 'react-redux'
import {
  fetchMetroList,
  getMetroList,
  isMetroFetching,
} from '../../../../../../../stores/dictionaries'
import TagsListField from './TagsListField'

const mapStateToProps = state => ({
  items: getMetroList(state),
  isFetching: isMetroFetching(state),
})

const MetroField = props => (
  <TagsListField placeholder="Выберите станции для замены тега..." {...props} />
)

export default connect(
  mapStateToProps,
  { fetchList: fetchMetroList },
)(MetroField)
