import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import sortBy from 'lodash/sortBy'
import styled from 'styled-components'
import { Button, Table, Message } from 'semantic-ui-react'
import { isFetching } from '../../../../../../../stores/keywords'
import AdsListItem from './AdsListItem'

const AvailableAdsBodyWrap = styled.div`
  height: calc(70vh - 260px);
  width: calc(100% + 10px);
  overflow-x: hidden;
  overflow-y: scroll;

  & .removable-text.removable-text {
    position: relative;
    padding-right: 30px;

    & .remove-btn {
      position: absolute;
      top: 2px;
      right: -1px;
      background: transparent;
      font-size: 14px;
      opacity: 0.4;
      transition: background 0.3s, color 0.3s;

      &:hover {
        color: white;
        background: #e08283;
        opacity: 1;
      }
    }
  }

  & .relevance-score {
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const mapStateToProps = state => ({ isKeywordsFetching: isFetching(state) })

class AvailableAds extends Component {
  constructor(props) {
    super(props)

    this.state = {
      column: 'rating',
      direction: 'descending',
    }
  }

  handleSort = clickedColumn => () => {
    const { column, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: clickedColumn === 'rating' ? 'descending' : 'ascending',
      })

      return
    }

    this.setState({
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }

  sortByColumn(ads) {
    const { column, direction } = this.state

    const sortedAds = sortBy(ads, el => {
      switch (column) {
        case 'title':
          return el.title.displayText
        case 'text':
          return el.text.displayText
        default:
          return el.rating
      }
    })

    if (direction === 'descending') {
      return sortedAds.reverse()
    }

    return sortedAds
  }

  render() {
    const ads = this.sortByColumn(this.props.ads)
    const { selectAd, rejectTitle, rejectText, isKeywordsFetching } = this.props
    const { column, direction } = this.state
    const theader = (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell disabled width={1} />
          <Table.HeaderCell
            width={4}
            sorted={column === 'title' ? direction : null}
            onClick={this.handleSort('title')}
          >
            Заголовок
          </Table.HeaderCell>
          <Table.HeaderCell
            width={7}
            sorted={column === 'text' ? direction : null}
            onClick={this.handleSort('text')}
          >
            Текст
          </Table.HeaderCell>
          <Table.HeaderCell
            width={2}
            textAlign="center"
            sorted={column === 'rating' ? direction : null}
            onClick={this.handleSort('rating')}
          >
            Рейтинг
          </Table.HeaderCell>
          <Table.HeaderCell width={2} disabled />
        </Table.Row>
      </Table.Header>
    )
    const tbody = (
      <Table.Body>
        {ads.map(item => (
          <AdsListItem key={item.id} ad={item} rejectTitle={rejectTitle} rejectText={rejectText}>
            <Button
              circular
              icon="thumbs outline up"
              loading={item.isChanging}
              onClick={() => selectAd(item)}
              disabled={isKeywordsFetching}
            />
            {this.props.hideAd && (
              <Button
                circular
                icon="thumbs outline down"
                onClick={() => this.props.hideAd(item.id)}
              />
            )}
          </AdsListItem>
        ))}
      </Table.Body>
    )
    const tfooter = (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="5" textAlign="right">
            Осталось: {ads.length} шт.
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    )

    return (
      <Fragment>
        <Table attached="top" sortable>
          {theader}
        </Table>
        <AvailableAdsBodyWrap>
          {ads.length ? (
            <Table attached celled striped>
              {tbody}
            </Table>
          ) : (
            <Message info content="К сожалению, нет объявлений подходящего качества." />
          )}
        </AvailableAdsBodyWrap>
        <Table attached="bottom">{tfooter}</Table>
      </Fragment>
    )
  }
}

AvailableAds.defaultProps = {
  hideAd: null,
}

AvailableAds.propTypes = {
  ads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      rating: PropTypes.number.isRequired,
      title: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
      text: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
      isChanging: PropTypes.bool,
    }),
  ).isRequired,
  selectAd: PropTypes.func.isRequired,
  rejectTitle: PropTypes.func.isRequired,
  rejectText: PropTypes.func.isRequired,
  hideAd: PropTypes.func,
  isKeywordsFetching: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps)(AvailableAds)
