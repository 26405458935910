import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { projectReducers } from 'projects-embed'
import ads from './ads'
import { titleExclusions, textExclusions } from './exclusions'
import keywords from './keywords'
import modal from './modal'
import newKeywords from './newKeywords'
import notifications from './notifications'
import tasks from './tasks'
import dictionaries from './dictionaries'
import user from './user'
import requestErrors from './requestErrors'

const rootReducer = combineReducers({
  projects: projectReducers,
  ads,
  dictionaries,
  keywords,
  modal,
  newKeywords,
  notifications,
  tasks,
  textExclusions,
  titleExclusions,
  form: formReducer,
  user,
  requestErrors,
})

export default rootReducer
