import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as filters from '../../../../../constants/keywords-filter'
import CountableItem from '../../../../components/CountableItem'
import { getVisibleKeywords } from '../../../../../stores/keywords'

function mapStateToProps(state, { filter, history, match }) {
  const { params } = match
  const path = ['', params.projectId, params.taskId]

  if (filter !== filters.DEFAULT_FILTER) {
    path.push(filter)
  }

  const visibleKeywords = getVisibleKeywords(state, filter)

  if (visibleKeywords.length) {
    path.push(`?id=${visibleKeywords[0].id}`)
  }

  return {
    active: filter === (params.filter || filters.DEFAULT_FILTER),
    count: visibleKeywords.length,
    onClick: () => {
      history.push(path.join('/'))
    },
  }
}

export default withRouter(connect(mapStateToProps)(CountableItem))
