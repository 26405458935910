import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import IncrementalInput from './components/IncrementalInput'
import { setMaxAds, setMinRating, getMaxAds, getMinRating } from '../../../../../../stores/tasks'

const AdsListControlsWrap = styled.div`
  display: flex;
  align-items: baseline;
  padding-top: 5px;
  margin-top: 5px;
  margin-bottom: 14px;
  height: 48.38px;

  .description {
    margin-right: 10px;
    user-select: none;
  }
`

const mapStateToProps = (state, { taskId }) => ({
  maxAds: getMaxAds(state, taskId),
  minRating: getMinRating(state, taskId),
})

const AdsListControls = ({ taskId, maxAds, minRating, setMaxAds, setMinRating }) => (
  <AdsListControlsWrap>
    <p className="description">Количество объявлений</p>
    <IncrementalInput min={1} value={maxAds} setValue={val => setMaxAds(taskId, val)} />
    <p className="description">Минимальный рейтинг</p>
    <IncrementalInput
      min={1}
      max={10}
      value={minRating}
      setValue={val => setMinRating(taskId, val)}
    />
  </AdsListControlsWrap>
)

AdsListControls.defaultProps = {
  maxAds: '',
  minRating: '',
}

AdsListControls.propTypes = {
  taskId: PropTypes.string.isRequired,
  maxAds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minRating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setMaxAds: PropTypes.func.isRequired,
  setMinRating: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  { setMaxAds, setMinRating },
)(AdsListControls)
