import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import zipObject from 'lodash/zipObject'

const tagRe = /<[^>]+>/g

const getTags = text => text.match(tagRe)

const prepareAdText = (text, displayText) => {
  const tags = getTags(text)

  if (text === displayText && (!tags || !tags.length)) {
    return displayText
  }

  const textPartsWithoutTags = text.split(new RegExp(tags.join('|')))
  const tagValues = compact(
    displayText.split(
      new RegExp(
        compact(textPartsWithoutTags)
          .map(part => part.replace(/[\\|/.*+?!^$()[\]{}]/g, '\\$&'))
          .join('|'),
      ),
    ),
  )
  const tagDict = zipObject(tags, tagValues)

  const textPartsWithTags = flatten(
    text.split(/(?=<[^>]+>)/).map(str => {
      const splitIdx = str.indexOf('>') + 1
      const tag = str.slice(0, splitIdx)

      return splitIdx ? [{ tag, value: tagDict[tag] }, str.slice(splitIdx)] : str
    }),
  )

  return compact(textPartsWithTags)
}

export default prepareAdText
