import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { Form, Grid, Icon, Message, Table } from 'semantic-ui-react'
import { makeTrans } from 'pannier'
import { addKeywords } from '../../../stores/newKeywords'
import getKeywords, { getStat, translateStatus } from '../../../utils/getKeywords'
import * as keyStatuses from '../../../constants/newKeywordStatuses'

const trans = makeTrans({
  processed_plural_0: ({ count }) => `Обработана ${count} строка`,
  processed_plural_1: ({ count }) => `Обработаны ${count} строки`,
  processed_plural_2: ({ count }) => `Обработано ${count} строк`,
  willBeUploaded_plural_0: ({ count }) => `из них ${count} будет загружена`,
  willBeUploaded_plural_1: ({ count }) => `из них ${count} будет загружено`,
  willBeUploaded_plural_2: ({ count }) => `из них ${count} будет загружено`,
})

const KeywordsTextArea = ({ handleSubmit, handleChange }) => (
  <Form onSubmit={handleSubmit}>
    <Grid>
      <Grid.Row centered>
        <Grid.Column width={12}>
          <Form.TextArea onChange={handleChange} rows={15} placeholder="Введите ключевые фразы.." />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12} />
        <Grid.Column width={2}>
          <Form.Button content="Добавить" floated="right" color="green" />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
)

const KeywordsNotification = ({ isUploading, stat, keywords, uploadKeywords, revert }) => (
  <Grid>
    <Grid.Row centered>
      <Grid.Column width={12}>
        <Message icon>
          <Icon name="hourglass zero" loading={isUploading} />
          <Message.Content>
            <Message.Header>
              {`${trans('processed', { count: keywords.length })}, ${trans('willBeUploaded', {
                count: stat.OK,
              })}`}
            </Message.Header>
            <Message.List>
              {stat.DOUBLE > 0 && <Message.Item>Обнаружено дублей: {stat.DOUBLE}</Message.Item>}
              {stat.INVALID > 0 && (
                <Message.Item>Не удалось распознать формат: {stat.INVALID}</Message.Item>
              )}
            </Message.List>
          </Message.Content>
        </Message>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={10} />
      <Grid.Column width={2}>
        <Form.Button
          content="Продолжить"
          onClick={uploadKeywords}
          floated="right"
          color="green"
          disabled={isUploading}
        />
      </Grid.Column>
      <Grid.Column width={2}>
        <Form.Button content="Отмена" onClick={revert} floated="right" disabled={isUploading} />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row centered>
      <Grid.Column width={10}>
        <KeywordsTable keywords={keywords} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

const KeywordsTable = ({ keywords }) => {
  const invalidKeywords = keywords.filter(item => item.status !== keyStatuses.NEW_KEYWORD_STATUS_OK)

  if (!invalidKeywords.length) {
    return null
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Группа</Table.HeaderCell>
          <Table.HeaderCell>Ключевая фраза</Table.HeaderCell>
          <Table.HeaderCell>Статус</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {invalidKeywords.map(keyword => (
          <Table.Row key={keyword.line}>
            <Table.Cell>{keyword.group}</Table.Cell>
            <Table.Cell>{keyword.keyword}</Table.Cell>
            <Table.Cell>{translateStatus(keyword.status)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

class KeywordsInputForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rawInput: '',
      keywords: [],
      submitted: false,
      stat: {},
    }
  }

  uploadKeywords = () => {
    const preparedKeywords = this.state.keywords
      .filter(kwd => kwd.status === keyStatuses.NEW_KEYWORD_STATUS_OK)
      .map(keyword => {
        const keywordGroup = { keyword: keyword.keyword }

        if (keyword.group) {
          keywordGroup.group = keyword.group
        }

        return keywordGroup
      })

    const {
      addKeywords,
      taskId,
      history,
      match: {
        params: { projectId },
      },
    } = this.props

    addKeywords(taskId, preparedKeywords, () => {
      history.push(`/${projectId}/${taskId}`)
    })
  }

  revert = () => {
    this.setState({ rawInput: '', submitted: false })
  }

  handleSubmit = () => {
    if (this.state.rawInput) {
      this.setState(
        prevState => {
          if (!prevState.rawInput) {
            return prevState
          }

          const keywords = getKeywords(prevState.rawInput.toLowerCase())

          return {
            keywords,
            stat: getStat(keywords),
            submitted: true,
          }
        },
        () => {
          const { keywords, stat } = this.state

          if (keywords && stat && keywords.length === stat.OK) {
            this.uploadKeywords()
          }
        },
      )
    }
  }

  handleChange = (e, { value }) => {
    this.setState({ rawInput: value })
  }

  render() {
    if (this.state.submitted) {
      return (
        <KeywordsNotification
          isUploading={this.props.isUploading}
          stat={this.state.stat}
          keywords={this.state.keywords}
          revert={this.revert}
          uploadKeywords={this.uploadKeywords}
        />
      )
    }

    return <KeywordsTextArea handleSubmit={this.handleSubmit} handleChange={this.handleChange} />
  }
}

const mapStateToProps = (state, { history, match }) => ({
  isUploading: state.newKeywords.isUploading,
  taskId: match.params.taskId,
  history,
})

const mapDispatchToProps = {
  addKeywords,
}

KeywordsInputForm.propTypes = {
  isUploading: PropTypes.bool.isRequired,
  addKeywords: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: {
      projectId: PropTypes.string.isRequired,
    }.isRequired,
  }).isRequired,
}

KeywordsTextArea.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
}

KeywordsNotification.propTypes = {
  isUploading: PropTypes.bool.isRequired,
  stat: PropTypes.objectOf(PropTypes.number).isRequired,
  uploadKeywords: PropTypes.func.isRequired,
  revert: PropTypes.func.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.object).isRequired,
}

KeywordsTable.propTypes = {
  keywords: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export const KeywordsInputFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(KeywordsInputForm)

export default withRouter(KeywordsInputFormContainer)
