import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  rejectTitle,
  selectTitle,
  deselectTitle,
  getAvailableTitles,
  getSelectedTitles,
} from '../../../../../stores/ads'
import TextsList from './TextsList'

function mapStateToProps(
  state,
  {
    match: {
      params: { taskId },
    },
  },
) {
  return {
    availableItems: getAvailableTitles(state, taskId),
    selectedItems: getSelectedTitles(state, taskId),
  }
}

function mapDispatchToProps(dispatch, { match }) {
  return {
    onRejectClick: id => dispatch(rejectTitle(match.params.taskId, id)),
    selectItem: id => dispatch(selectTitle(id)),
    deselectItem: id => dispatch(deselectTitle(id)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TextsList),
)
