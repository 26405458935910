import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Dropdown } from 'semantic-ui-react'
import { fetchGroupInfo } from '../../../../../../../stores/keywords'

const mapStateToProps = (state, { keyword }) => ({
  initialKeywords:
    keyword && keyword.initialKeywords
      ? keyword.initialKeywords.slice().sort((a, b) => a.localeCompare(b))
      : [],
})

class InitialKeywords extends Component {
  fetchGroupInfo = () => {
    const { initialKeywords, taskId, keyword, fetchGroupInfo } = this.props
    if (initialKeywords && !initialKeywords.length) {
      fetchGroupInfo(taskId, keyword.id)
    }
  }

  render() {
    const { keyword, initialKeywords } = this.props

    if (!keyword) {
      return null
    }

    if (keyword.initialKeywordsCount < 2) {
      return null
    }

    return (
      <Dropdown
        size="small"
        loading={keyword.isFetching}
        icon="file outline"
        scrolling
        onClick={this.fetchGroupInfo}
      >
        <Dropdown.Menu>
          {initialKeywords.map(item => (
            <Dropdown.Item key={item} disabled className="no-opacity">
              {item}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

InitialKeywords.defaultProps = {
  keyword: null,
}

InitialKeywords.propTypes = {
  taskId: PropTypes.string.isRequired,
  keyword: PropTypes.shape({
    id: PropTypes.string.isRequired,
    initialKeywords: PropTypes.arrayOf(PropTypes.string),
    initialKeywordsCount: PropTypes.number,
  }),
  initialKeywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchGroupInfo: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  { fetchGroupInfo },
)(InitialKeywords)
