import React from 'react'
import { render } from 'react-dom'
import Root from './views/Root'
import configureStore from './stores/configureStore'
import './rollbar'
import './styles'

const store = configureStore()

render(<Root store={store} />, document.getElementById('root'))
