import Rollbar from 'rollbar'

let options

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ROLLBAR_TOKEN) {
  options = {
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.NODE_ENV,
      client: {
        javascript: {
          code_version: process.env.REACT_APP_REVISION,
          source_map_enabled: true,
        },
      },
    },
  }
} else {
  options = {
    enabled: false,
  }
}

const rollbar = new Rollbar(options)

export default rollbar
