import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { signOut } from 'auth-component'
import LoginButton from './components/LoginButton'
import { fetchProfile, logout, isFetching, getLogin } from '../../../../../stores/user'

const mapStateToProps = state => ({
  isFetching: isFetching(state),
  login: getLogin(state),
})

class LoginButtonContainer extends Component {
  constructor(props) {
    super(props)

    this.signOut = signOut
  }

  componentDidMount() {
    this.props.fetchProfile()
  }

  handleSignOut = () => {
    this.signOut()
    this.props.logout()
  }

  render() {
    const { isFetching, login } = this.props

    return <LoginButton isFetching={isFetching} login={login} handleSignOut={this.handleSignOut} />
  }
}

LoginButtonContainer.defaultProps = {
  login: null,
}

LoginButtonContainer.propTypes = {
  fetchProfile: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  login: PropTypes.string,
}

export default connect(
  mapStateToProps,
  { fetchProfile, logout },
)(LoginButtonContainer)
