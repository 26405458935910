import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'semantic-ui-react'

const ConfirmModal = ({ content, onConfirm, closeModal }) => (
  <Modal size="tiny" open>
    <Modal.Header>Подтвердите действие</Modal.Header>
    <Modal.Content>{content}</Modal.Content>
    <Modal.Actions>
      <Button
        content="Ок"
        positive
        onClick={() => {
          closeModal()
          onConfirm()
        }}
      />
      <Button content="Отмена" negative onClick={closeModal} />
    </Modal.Actions>
  </Modal>
)

ConfirmModal.propTypes = {
  content: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default ConfirmModal
