import { RSAA, getJSON } from 'redux-api-middleware'
import { normalize } from 'normalizr'
import { camelizeKeys, decamelizeKeys } from 'humps'
import * as schemas from './schemas'
import { TASK_PAGE_SIZE } from '../../constants'
import * as types from './index'
import * as modalTypes from '../../constants/modal-types'
import { getTask, isTaskUpdating } from './selectors'
import { hideModal } from '../modal'
import * as requestHeaders from '../../constants/request-headers'

export const setMaxAds = (taskId, value) => ({
  type: types.SET_MAX_ADS,
  meta: { taskId },
  value,
})

export const setMinRating = (taskId, value) => ({
  type: types.SET_MIN_RATING,
  meta: { taskId },
  value,
})

// async actions

export const fetchTasks = (projectId, limit = TASK_PAGE_SIZE, offset = 0) => ({
  [RSAA]: {
    endpoint: `/api/tasks?project=${projectId}&limit=${limit}&offset=${offset}`,
    method: 'GET',
    credentials: 'same-origin',
    options: {
      authRequired: true,
    },
    bailout: state => state.tasks.isFetching,
    types: [
      {
        type: types.FETCH_LIST_REQUEST,
        meta: {
          offset,
        },
      },
      {
        type: types.FETCH_LIST_SUCCESS,
        payload: (action, state, res) =>
          getJSON(res.clone()).then(json => normalize(json.data, schemas.TASK_ARRAY)),
        meta: (action, state, res) => getJSON(res.clone()).then(json => camelizeKeys(json.meta)),
      },
      types.FETCH_LIST_FAILURE,
    ],
  },
})

export const fetchTaskInfo = taskId => ({
  [RSAA]: {
    endpoint: `/api/tasks/${taskId}`,
    method: 'GET',
    credentials: 'same-origin',
    options: {
      authRequired: true,
    },
    types: [
      types.FETCH_INFO_REQUEST,
      {
        type: types.FETCH_INFO_SUCCESS,
        payload: (action, state, res) =>
          getJSON(res).then(json => normalize(json.data, schemas.TASK)),
      },
      types.FETCH_INFO_FAILURE,
    ],
  },
})

export const createTask = (projectId, task, callback) => dispatch =>
  dispatch({
    [RSAA]: {
      endpoint: `/api/tasks?project=${projectId}`,
      method: 'POST',
      credentials: 'same-origin',
      headers: requestHeaders.POST,
      options: {
        authRequired: true,
      },
      bailout: state => state.tasks.isCreating,
      body: JSON.stringify({
        data: task,
      }),
      types: [
        types.CREATE_REQUEST,
        {
          type: types.CREATE_SUCCESS,
          payload: (action, state, res) => {
            dispatch(hideModal(modalTypes.CREATE_TASK_FORM))
            return getJSON(res).then(json => normalize(json.data, schemas.TASK))
          },
        },
        types.CREATE_FAILURE,
      ],
    },
  }).then(action => {
    if (callback && action.type === types.CREATE_SUCCESS) {
      callback(action.payload.result)
    }
  })

export const updateTask = (taskId, data) => ({
  [RSAA]: {
    endpoint: `/api/tasks/${taskId}`,
    method: 'PATCH',
    credentials: 'same-origin',
    headers: requestHeaders.POST,
    options: {
      authRequired: true,
    },
    bailout: state => isTaskUpdating(state, taskId),
    body: JSON.stringify({
      data: decamelizeKeys(data),
    }),
    types: [
      {
        type: types.UPDATE_REQUEST,
        meta: { taskId },
      },
      {
        type: types.UPDATE_SUCCESS,
        payload: (action, state, res) =>
          getJSON(res).then(json => normalize(json.data, schemas.TASK)),
        meta: { taskId },
      },
      {
        type: types.UPDATE_FAILURE,
        meta: { taskId },
      },
    ],
  },
})

export const deleteTask = taskId => ({
  [RSAA]: {
    endpoint: `/api/tasks/${taskId}`,
    method: 'DELETE',
    credentials: 'same-origin',
    options: {
      authRequired: true,
    },
    bailout: state => getTask(state, taskId).isDeleting,
    types: [
      {
        type: types.DELETE_REQUEST,
        meta: {
          taskId,
        },
      },
      {
        type: types.DELETE_SUCCESS,
        meta: {
          taskId,
        },
      },
      {
        type: types.DELETE_FAILURE,
        meta: {
          taskId,
        },
      },
    ],
  },
})

export const downloadReport = taskId => ({
  [RSAA]: {
    endpoint: `/api/tasks/${taskId}/downloads`,
    method: 'GET',
    credentials: 'same-origin',
    headers: requestHeaders.GET,
    options: {
      authRequired: true,
    },
    bailout: state => getTask(state, taskId).isReportFetching,
    types: [
      {
        type: types.FETCH_REPORT_REQUEST,
        meta: {
          taskId,
        },
      },
      {
        type: types.FETCH_REPORT_SUCCESS,
        payload: (action, state, res) => getJSON(res).then(json => camelizeKeys(json.data)),
        meta: {
          taskId,
        },
      },
      {
        type: types.FETCH_REPORT_FAILURE,
        meta: {
          taskId,
        },
      },
    ],
  },
})
