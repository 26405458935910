import * as routes from '../constants/routes'
import * as pageNames from '../constants/page-names'

const getTitleElements = (route, { projectName, taskName }) => {
  switch (route) {
    case '/':
      return [pageNames.MAIN]
    case routes.TASKS_LIST_PAGE:
      return [projectName]
    case routes.TASK_PAGE:
      return [pageNames.TASK, taskName, projectName]
    case routes.EXCLUSIONS_PAGE:
      return [pageNames.EXCLUSIONS, taskName, projectName]
    case routes.NEWKEYWORDS_PAGE:
      return [pageNames.NEWKEYWORDS, taskName, projectName]
    // no default
  }
  return null
}

const createPageTitle = (route, options) => {
  const titleElements = getTitleElements(route, options)
  const baseName = 'AirMedia.Dasha'

  if (!titleElements) {
    return baseName
  }

  return [...titleElements.filter(item => !!item), baseName].join(' | ')
}

export default createPageTitle
